import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Avatar, Chip } from "@material-tailwind/react";
import { useTranslation } from "react-i18next";
import DefaultPictureProfil from "../../assets/images/elements/default-profile.png"

export default function CardUserWithData({ data, isVerified }: Readonly<{ data?: { id: string, name: string, picture: string, publicLink: string, plan?: string, verified?: boolean }, isVerified: boolean }>) {
    const { t } = useTranslation();

    const returnColor = () => {
        if (data?.plan === "PRO") return "bg-black text-white";
        else if (data?.plan === "PREMIUM") return "bg-white text-black border border-black";
        else return;
    }

    const returnValue = () => {
        if (data?.plan === "PRO") return t("pro");
        else if (data?.plan === "PREMIUM") return t("premium");
        else return;
    }

    const returnIcon = () => {
        if (data?.plan === "PRO") return <FontAwesomeIcon icon="crown" className="h-2.5 mx-0.5" />;
        else if (data?.plan === "PREMIUM") return <FontAwesomeIcon icon="star" className="h-2.5 mx-0.5" />;
        else return;
    }

    return (
        <a href={`/${data?.publicLink}/photos`} className="flex flex-row items-center gap-2 px-2 w-auto rounded-xl py-2">
            <Avatar src={data?.picture ?? DefaultPictureProfil} />
            <div className="flex flex-col">
                <p className="text-lg font-bold flex flex-row gap-2 items-center">
                    <span className="whitespace-nowrap line-clamp-1 text-ellipsis overflow-hidden">{data?.name}</span>
                    {isVerified && (<FontAwesomeIcon icon="check-circle" className="text-gray-500" />)}
                </p>
                {data?.plan !== "USER" && data?.plan !== "PHOTOGRAPHER" &&
                    <Chip
                        className={`w-fit h-fit ${returnColor()} p-1 text-[10px] gap-1`}
                        variant="ghost"
                        value={returnValue()}
                        icon={returnIcon()}
                        size="sm"
                    />
                }
            </div>
        </a>
    );
}
