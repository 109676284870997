import React, { ReactNode, createContext, useContext, useMemo, useState } from 'react';
import PictureModal from '../components/Box/Photo/PictureModal';
import { PictureSearch } from '../components/Interfaces/PictureType';

interface OpenPictureData {
    id: string;
    context: PictureSearch[];
    loadMore: LoadMoreFunction;
}

interface PictureModalContextType {
    open: OpenPictureData | undefined;
    setOpen: React.Dispatch<React.SetStateAction<OpenPictureData | undefined>>;
}

const PictureModalContext = createContext<PictureModalContextType | undefined>(undefined);

type LoadMoreFunction = () => void;

export const PictureModalProvider: React.FC<{ children: ReactNode }> = ({ children }) => {
    const [open, setOpen] = useState<OpenPictureData | undefined>(undefined);

    const values = useMemo(() => ({ open, setOpen }), [open, setOpen]);

    const closeModal = () => {
        setOpen(undefined);
    }

    return (
        <PictureModalContext.Provider value={values}>
            <PictureModal open={open !== undefined} setOpen={closeModal} ids={open?.id ?? ""} pictures={open?.context ?? []} />
            {children}
        </PictureModalContext.Provider>
    );
};

// Custom hook to consume the context
export const usePictureModal = () => {
    const context = useContext(PictureModalContext);
    if (!context) {
        throw new Error('usePictureModal must be used within a PictureModalProvider');
    }

    return context;
};