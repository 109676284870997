import { Reducer } from "@reduxjs/toolkit";
import { UserType } from "../../../components/Interfaces/UserType";

const initialState: UserType = {
    id: "",
    email: "",
    roles: [],
    firstname: "",
    lastname: "",
    company: "",
    portfolio: {
        id: "",
    },
    createdAt: "",
    plan: "USER",
    verified: false,
};

const userReducer: Reducer<UserType, any> = (state = initialState, action) => {
    switch (action.type) {
        case "UPDATE_ID":
            return { ...state, id: action.payload };
        case "UPDATE_EMAIL":
            return { ...state, email: action.payload };
        case "UPDATE_ROLES":
            return { ...state, roles: action.payload };
        case "UPDATE_FIRSTNAME":
            return { ...state, firstname: action.payload };
        case "UPDATE_LASTNAME":
            return { ...state, lastname: action.payload };
        case "UPDATE_COMPANY":
            return { ...state, company: action.payload };
        case "UPDATE_PORTFOLIO":
            return { ...state, portfolio: action.payload };
        case "UPDATE_CREATEDAT":
            return { ...state, createdAt: action.payload };
        case "UPDATE_ACCOUNTPLAN":
            return { ...state, plan: action.payload };
        case "UPDATE_USER":
            return { ...state, ...action.payload };
        default:
            return state;
    }
};

export default userReducer;
