import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Menu, Transition } from "@headlessui/react";
import { Fragment, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Storage } from "../../../services/storage";
import PicturesAPI from "../../../services/API/Clients/PicturesAPI";
import { useSelector } from "react-redux";
import CatsAPI from "../../../services/API/Clients/CatsAPI";
import ServiceTypeAPI from "../../../services/API/Clients/ServiceTypeAPI";
import TypeStyleAPI from "../../../services/API/Clients/TypeAPI";
import StyleAPI from "../../../services/API/Clients/StyleAPI";
import { useLocation, useNavigate } from "react-router-dom";
import { Typography } from "@material-tailwind/react";
import { handleError } from "../../../services/Errors/handleErrors";

export default function DropDown({
    title,
    side = "right",
    mainParam,
    from,
    icon,
    pathname,
    button = false,
}: Readonly<{
    title: string;
    side?: string;
    mainParam: string;
    from: string;
    icon: any;
    pathname: string;
    button?: boolean;
}>) {
    const { t } = useTranslation();
    const url = new URL(window.location.href);
    const location = useLocation();
    const currentPathname = location.pathname.split("/");
    const mainCategory = url.searchParams.get("main_param");
    const myLang = Storage.getLanguage();
    const [list, setList] = useState<any>([]);
    const [open, setOpen] = useState(false);
    const general = useSelector((value: any) => value.general);
    const navigate = useNavigate();
    
    useEffect(() => {
        const fetchData = async () => {
            switch (title) {
                case "categories":
                    const responseCats = await CatsAPI.publicCatsMostUsed();
                    if (responseCats.status === 200 || responseCats.status === 201) setList(responseCats.body);
                    else handleError(responseCats);
                    break;
                case "type":
                    const responseTypes = await TypeStyleAPI.typesMostUsed();
                    if (responseTypes.status === 200 || responseTypes.status === 201) setList(responseTypes.body);
                    else handleError(responseTypes);
                    break;
                case "userights":
                    const responseLicenses = await PicturesAPI.licensesMostUsed();
                    if (responseLicenses.status === 200 || responseLicenses.status === 201) setList(responseLicenses.body);
                    else handleError(responseLicenses);
                    break;
                case "perfphoto":
                    const responsePerf = await ServiceTypeAPI.servicesMostUsed();
                    if (responsePerf.status === 200 || responsePerf.status === 201) setList(responsePerf.body);
                    else handleError(responsePerf);
                    break;
                case "photoStyle":
                    const responseStyle = await StyleAPI.stylesMostUsed();
                    if (responseStyle.status === 200 || responseStyle.status === 201) setList(responseStyle.body);
                    else handleError(responseStyle);
                    break;
                default:
                    break;
            }
        };
        if (!open) return;
        fetchData();
    }, [open]);

    return (
        <Menu as="div" className="relative inline-block text-left">
            <div>
                <Menu.Button
                    className={`${
                        currentPathname[2] === mainParam && from === "photo"
                            ? `text-orange-500 hover:text-orange-200 ${!button && "border-b-2 border-b-orange-500"}`
                            : currentPathname[2] === mainParam && from === "photographers"
                            ? `text-purple-500 hover:text-purple-200 ${!button && "border-b-2 border-b-purple-500"}`
                            : "text-gray-500 hover:text-black border-b-2 border-transparent hover:border-b-black"
                    } inline-flex w-full justify-center ${button ? "my-2 p-2" : "pb-2.5 pt-1 px-2"} text-sm items-center font-light font-comfortaa transition-all`}
                    onClick={(e) => setOpen(!open)}
                >
                    <FontAwesomeIcon
                        icon={icon}
                        className={`font-thin mr-1.5`}
                    />
                    {t(title)}
                    <FontAwesomeIcon
                        icon="chevron-down"
                        className={`-mr-1 h-3 w-3  font-thin ml-1.5`}
                        aria-hidden="true"
                    />
                </Menu.Button>
            </div>

            <Transition
                as={Fragment}
                enter="transition ease-out duration-100"
                enterFrom="transform opacity-0 scale-95"
                enterTo="transform opacity-100 scale-100"
                leave="transition ease-in duration-75"
                leaveFrom="transform opacity-100 scale-100"
                leaveTo="transform opacity-0 scale-95"
            >
                <Menu.Items
                    className={`absolute ${side}-0 z-50 flex flex-col px-2 py-0.5 w-fit origin-top-right rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none font-comfortaa font-thin md:max-h-[200px] lg:max-h-[445px] overflow-y-auto`}
                >
                    {list.length === 0 ? (
                        <div className="flex flex-col justify-start items-start w-full rounded-lg bg-gray-100 my-2 text-left whitespace-nowrap px-4">
                            <Typography className="text-base text-gray-700 my-4">{t("noData")}</Typography>
                        </div>
                    ) : title === "userights" ? (
                        list.map((item: string) => {
                            return (
                                <button
                                    onClick={() => navigate(`/search/${mainParam}/${item}`)}
                                    key={item}
                                    className="text-left min-w-[8rem]"
                                >
                                    <Menu.Item>
                                        <button className="my-1 hover:bg-gradient-to-r hover:from-orange-500 hover:to-orange-100 hover:text-white text-gray-500 p-2 text-sm w-full text-left rounded-lg whitespace-nowrap">
                                            {t(item)}
                                        </button>
                                    </Menu.Item>
                                </button>
                            );
                        })
                    ) : (
                        list
                            .sort((a: any, b: any) => (a.frLabel > b.frLabel ? 1 : b.frLabel > a.frLabel ? -1 : 0))
                            .map((item: { enumValue: string; frLabel: string; enLabel: string }) => (
                                <button
                                    onClick={() => navigate(`/${from === "photo" ? "search" : "photographers"}/${mainParam}/${item.enumValue}`)}
                                    key={item.enumValue}
                                    className="text-left min-w-[8rem]"
                                >
                                    <Menu.Item>
                                        <button className="my-1 hover:bg-gradient-to-r hover:from-orange-500 hover:to-orange-100 hover:text-white text-gray-500 p-2 text-sm w-full text-left rounded-lg whitespace-nowrap">
                                            {myLang === "end" ? item.enLabel : item.frLabel}
                                        </button>
                                    </Menu.Item>
                                </button>
                            ))
                    )}
                </Menu.Items>
            </Transition>
        </Menu>
    );
}
