import { useTranslation } from "react-i18next";
import PopularWaves from "../../../assets/images/home/waves/popular.svg";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Button, Checkbox, Input } from "@material-tailwind/react";
import React from "react";
import NewsletterAPI from "../../../services/API/Clients/NewsletterAPI";
import { toast } from "react-toastify";

export default function Register() {
    const { t } = useTranslation();
    const [email, setEmail] = React.useState("");
    const [lastname, setLastname] = React.useState("");
    const [firstname, setFirstname] = React.useState("");
    const [company, setCompany] = React.useState("");
    const [checked, setChecked] = React.useState(false);
    const [checkedBis, setCheckedBis] = React.useState(false);

    const handleSubmit = async (e: React.FormEvent) => {
        e.preventDefault();

        if (!email.match(/^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/)) {
            toast.warning("Veuillez entrer une adresse email valide.");
            return;
        }
        
        const datas = {
            email,
            lastname,
            firstname,
            ...(company && { company }),
        };

        const response = await NewsletterAPI.subscribe(datas);

        if (response.status === 200 || response.status === 201) {
            toast.success("Votre inscription à la newsletter a bien été prise en compte !");
        } else if (response.body.detail === `email: This value is already used.`) {
            toast.warning("Vous êtes déjà inscrit à la newsletter.");
        } else if (response.body.detail === "email: This value is not a valid email address.") {
            toast.warning("Veuillez entrer une adresse email valide.");
        } else if (response.body.detail === "firstname: This value should not be blank.\nfirstname: This value is too short. It should have 1 character or more.") {
            toast.warning("Veuillez entrer un prénom valide.");
        } else if (response.body.detail === "lastname: This value should not be blank.\nlastname: This value is too short. It should have 1 character or more.") {
            toast.warning("Veuillez entrer un nom valide.");
        } else if (response.body.detail === "company: This value is too short. It should have 1 character or more.") {
            toast.warning("Veuillez entrer un nom d'entreprise valide.");
        } else {
            toast.error("Une erreur est survenue.");
        }
    }

    return (
        <div className="m-0 pb-8 md:pb-32 min-h-fit 2xl:min-h-[120vh] relative bg-white overflow-hidden bg-no-repeat bg-image-popular bg-cover font-sans">
            <PopularWaves className="absolute z-0  min-w-full min-h-screen" />
            <div className="relative z-10 pb-4 max-w-7xl mx-auto 2xl:pt-24">
                <p className="mt-48 px-2 md:px-0 text-center text-2xl md:text-4xl w-full md:w-2/3 mx-auto">{t("subscribeNewsletter")}</p>
                <div className="flex flex-col md:flex-row justify-center  w-full mt-8 py-1 px-4 mx-auto">
                    <div className="flex flex-col w-full md:w-1/2 md:px-4 md:mt-4 gap-8">
                        <p className="md:px-0 text-left mt-4 mx-auto font-sans text-lg">{t("subscribeNewsletter_d")}</p>
                        <div className="flex flex-row justify-start items-start md:items-start gap-4 mt-4">
                            <FontAwesomeIcon icon="wand-magic-sparkles" className="text-2xl text-purple-500 mt-1" />
                            <h4 className="text-left text-lg font-bold">{t("subscribeNewsletter_p1")}</h4>
                        </div>
                        <div className="flex flex-row justify-start items-start gap-4 mt-4">
                            <FontAwesomeIcon icon="gift" className="text-2xl text-purple-500 mt-1" />
                            <h4 className="text-left text-lg font-bold">{t("subscribeNewsletter_p2")}</h4>
                        </div>
                    </div>
                    <div className="flex flex-col w-full md:w-1/2  mt-4">
                        <div className="flex flex-col justify-start gap-4 mt-4 bg-white py-4 md:p-4 rounded-lg">
                            <h4 className="text-center text-lg text-purple-500 font-bold">{t("subscribeForm_title")}</h4>
                            <form onSubmit={handleSubmit} className="flex flex-col justify-start gap-4">
                                <Input onChange={(e) => setCompany(e.target.value)} value={company} color="purple" type="text" label={t("subscribeForm_company")} />
                                <div className="flex flex-col md:flex-row justify-start items-end gap-4">
                                    <Input containerProps={{ className: "min-w-[72px]" }} onChange={(e) => setFirstname(e.target.value)} value={firstname} color="purple" type="text" label={t("firstname")} required />
                                    <Input containerProps={{ className: "min-w-[72px]" }} onChange={(e) => setLastname(e.target.value)} value={lastname} color="purple" type="text" label={t("name")} required />
                                </div>
                                <Input onChange={(e) => setEmail(e.target.value)} color="purple" type="email" label={t("mail")} required />
                                <div className="flex flex-row justify-start items-center">
                                    <Checkbox color="purple" onChange={(e) => setChecked(!checked)} required />
                                    <p className="text-left text-sm" dangerouslySetInnerHTML={{ __html: t("subscribeForm_email_d")}}></p>
                                </div>
                                <div className="flex flex-row justify-start items-center -mt-4">
                                    <Checkbox color="purple" onChange={(e) => setCheckedBis(!checkedBis)} required />
                                    <p className="text-left text-sm" dangerouslySetInnerHTML={{ __html: t("subscribeForm_email_d_2")}}></p>
                                </div>
                                <Button type="submit" color="orange" disabled={!checked || !checkedBis}>{t("subscribeForm_submit")}</Button>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}
