import { faCamera, faChevronDown, faChevronUp, faCircleHalfStroke, faClock, faRulerCombined, faSquare } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { ExifDaum } from "../../Interfaces/PictureType";
import { useState } from "react";
import { useTranslation } from "react-i18next";

interface Section {
    icon: JSX.Element,
    format: string[]
}

const Sections: Section[] = [
    {
        icon: <FontAwesomeIcon icon={faRulerCombined} />,
        format: ["ezoom.width", "x", "ezoom.height"],
    },
    {
        icon: <FontAwesomeIcon icon={faCamera} />,
        format: ["ifd0.MAKE", "ifd0.Model"],
    },
    {
        icon: <FontAwesomeIcon icon={faCircleHalfStroke} />,
        format: ["computed.ApertureFNumber"]
    },
    {
        icon: <FontAwesomeIcon icon={faCircleHalfStroke} />,
        format: ["exif.UndefinedTag:0xA434", "exif.ApertureValue"]
    },
    {
        icon: <FontAwesomeIcon icon={faClock} />,
        format: ["exif.ExposureTime"]
    }
];

const formatString = (data: ExifDaum[], format: string[]) => {
    const result = format.map((key) => {
        if (key.includes(".")) {
            return data.find((item) => item.key.toLowerCase() === key.toLowerCase())?.value;
        } else {
            return key;
        }
    });
    const removeUndefined = result.filter((item) => item !== undefined);
    return removeUndefined.join(" ");
}

const enrichData = (data: ExifDaum[], size: { width: number, height: number }): ExifDaum[] => {
    return [
        ...data,
        {
            key: 'ezoom.width',
            value: size.width.toString()
        },
        {
            key: 'ezoom.height',
            value: size.height.toString()
        }
    ];
}

const countAvailableExif = (d: ExifDaum[], size: { width: number, height: number }) => {
    const data = enrichData(d, size);
    return Sections.filter((section) => {
        const formatted = formatString(data, section.format);
        return formatted !== "";
    }).length;
}

export const hasAvailableExif = (d: ExifDaum[], size: { width: number, height: number }) => {
    return countAvailableExif(d, size) > 0;
}

interface ExifDataListProps {
    size: { width: number, height: number }
    data: ExifDaum[]
}

export default function ExifDataList({ data, size }: Readonly<ExifDataListProps>) {

    const { t } = useTranslation();

    const [expanded, setExpanded] = useState(false);

    const sections = expanded ? Sections : Sections.slice(0, 2);
    const availableSections = countAvailableExif(data, size);
    const showExpand = availableSections > 2;

    const enrichedData = enrichData(data, size);
    return (
        <div>
            {sections.map((section, index) => {
                const formatted = formatString(enrichedData, section.format);
                if (formatted === "") {
                    return null;
                }
                return (
                    <div key={formatted} className="flex items-center">
                        <div className="mr-2 text-gray-600">
                            {section.icon}
                        </div>
                        <div>
                            {formatted}
                        </div>
                    </div>
                )
            })}
            {showExpand && (expanded ? (
                <button className="text-gray-600 cursor-pointer mt-2" onClick={() => setExpanded(false)}>
                    <FontAwesomeIcon icon={faChevronUp} className="mr-2" />
                    {t("showlessexif")}
                </button>
            ) : (
                <button className="text-gray-600 cursor-pointer mt-2" onClick={() => setExpanded(true)}>
                    <FontAwesomeIcon icon={faChevronDown} className="mr-2" />
                    {t("showmoreexif")}
                </button>
            ))}
        </div>
    )
}