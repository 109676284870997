import { Reducer } from "@reduxjs/toolkit";
import { ContractType } from "../../../components/Interfaces/ContractType";

const initialState: ContractType = {
    name: "",
    available: "UNLIMITED",
    until: "",
    exclusive: false,
    limited: false,
    commercialUse: false,
    autonomous: false,
    branding: false,
    forSale: "null",
    digitalModels: false,
    print: false,
    useByEmployerClient: true,
    useBySubcontractor: true,
    userCount: "SINGLE",
    basePrice: 0
    
};

const contractReducer: Reducer<ContractType, any> = (state = initialState, action) => {
    switch (action.type) {
        case "UPDATE_NAME":
            return { ...state, name: action.payload };
        case "UPDATE_AVAILABLE":
            return { ...state, available: action.payload };
        case "UPDATE_ORIGINALCOUNT":
            return { ...state, originalCount: action.payload };
        case "UPDATE_UNTIL":
            return { ...state, until: action.payload };
        case "UPDATE_EXCLUSIVE":
            return { ...state, exclusive: action.payload };
        case "UPDATE_LIMITED":
            return { ...state, limited: action.payload };
        case "UPDATE_LIMITEDCOUNT":
            return { ...state, limitedCount: action.payload };
        case "UPDATE_COMMERCIALUSE":
            return { ...state, commercialUse: action.payload };
        case "UPDATE_AUTONOMOUS":
            return { ...state, autonomous: action.payload };
        case "UPDATE_BRANDING":
            return { ...state, branding: action.payload };
        case "UPDATE_FORSALE":
            return { ...state, forSale: action.payload };
        case "UPDATE_SALEPAPER":
            return { ...state, salePaper: action.payload };
        case "UPDATE_SALEDESK":
            return { ...state, saleDesk: action.payload };
        case "UPDATE_SALEMERCH":
            return { ...state, saleMerch: action.payload };
        case "UPDATE_DIGITALMODELS":
            return { ...state, digitalModels: action.payload };
        case "UPDATE_PRINT":
            return { ...state, print: action.payload };
        case "UPDATE_PRINTCOUNT":
            return { ...state, printCount: action.payload };
        case "UPDATE_USEBYEMPLOYERCLIENT":
            return { ...state, useByEmployerClient: action.payload };
        case "UPDATE_USEBYSUBCONTRACTOR":
            return { ...state, useBySubcontractor: action.payload };
        case "UPDATE_USERCOUNT":
            return { ...state, userCount: action.payload };
        case "UPDATE_BASEPRICE":
            return { ...state, basePrice: action.payload }; 
        case "UPDATE_CONTRACT":
            return { ...state, ...action.payload };
        default:
            return state;
    }
};

export default contractReducer;
