import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Popover, PopoverContent, PopoverHandler } from "@material-tailwind/react";
import moment from "moment";
import { useEffect, useState } from "react";
import Calendar from "react-calendar";
import "react-calendar/dist/Calendar.css";
import { useTranslation } from "react-i18next";
import { Link, useNavigate } from "react-router-dom";
import PicturesAPI from "../../services/API/Clients/PicturesAPI";
import RegionAPI from "../../services/API/Clients/RegionAPI";
import DropDown from "./composants/DropDown";
import Map from "./composants/Map";
import { handleError } from "../../services/Errors/handleErrors";

export default function SubMenu({ pathname }: Readonly<{ pathname: string }>) {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const [open, setOpen] = useState(false);
    const [openDates, setOpenDates] = useState(false);
    const [regions, setRegions] = useState([]);
    const [dates, setDates] = useState<string[]>([]);
    const [hoveredArea, setHoveredArea] = useState("");

    useEffect(() => {
        const fetchData = async () => {
            if (
                pathname.startsWith("/search/category") ||
                pathname.startsWith("/search/typestyle") ||
                pathname.startsWith("/search/license") ||
                pathname.startsWith("/search/region") ||
                pathname.startsWith("/search/date") ||
                pathname === "/favorites" ||
                pathname === "/recent" ||
                pathname === "/galeries" ||
                pathname === "/popular"
            ) {
                const response = await RegionAPI.mostUsed();
                if (response.status === 200 || response.status === 201) {
                    setRegions(response.body);
                } else {
                    handleError(response);
                }
            } else {
                const response = await RegionAPI.mostUsedPortfolio();
                if (response.status === 200 || response.status === 201) {
                    setRegions(response.body);
                } else {
                    handleError(response);
                }
            }
        };
        fetchData();
    }, [open]);

    useEffect(() => {
        const fetchData = async () => {
            const response = await PicturesAPI.datesMostUsed();
            if (response.status === 200 || response.status === 201) {
                setDates(response.body);
            } else {
                handleError(response);
            }
        };
        fetchData();
    }, [openDates]);

    function isDateDisabled(date: Date) {
        if (dates.length === 0) return true;
        const dateString = moment(date).format("YYYY-MM-DD");
        return !dates.includes(dateString);
    }

    const tileClassName = ({ date }: { date: Date }) => {
        const classes: string[] = [];
        
        if (isToday(date)) {
          classes.push("today");
        }
        
        return classes.join(" ");
    };

    const isToday = (date: Date) => {
        const today = new Date();
        return (
          date.getDate() === today.getDate() &&
          date.getMonth() === today.getMonth() &&
          date.getFullYear() === today.getFullYear()
        );
    };

    return (
        <div className={`hidden lg:flex w-full 2xl:w-5/6 mx-auto flex-wrap justify-center gap-4 md:gap-6 text-base px-8 -mt-2 transition-all`}>
            <Link
                className="text-center w-fit"
                to={
                    pathname === "/favorites/photographers" ||
                    pathname === "/photographers" ||
                    pathname.startsWith("/photographers/") ||
                    pathname === "/popular-photographers" ||
                    pathname === "/recent-photographers"
                        ? "/popular-photographers"
                        : "/popular"
                }
            >
                <div
                    className={`font-light pb-2.5 pt-1 text-sm font-comfortaa ${
                        pathname === "/popular"
                            ? " border-b-2 border-orange-500 px-2 text-orange-500 hover:text-orange-200 hover:border-orange-200 transition-all"
                            : pathname === "/popular-photographers"
                            ? "border-b-2 border-purple-500 px-2 text-purple-500 hover:text-purple-200 hover:border-purple-200 transition-all"
                            : "border-b-2 border-transparent text-gray-500 hover:text-black hover:border-black transition-all"
                    } px-2`}
                >
                    <FontAwesomeIcon icon="fire" className={`font-thin mr-1.5`} />
                    {t("populars")}
                </div>
            </Link>
            <Link
                to={
                    pathname === "/favorites/photographers" ||
                    pathname === "/photographers" ||
                    pathname.startsWith("/photographers/") ||
                    pathname === "/popular-photographers" ||
                    pathname === "/recent-photographers"
                        ? "/recent-photographers"
                        : "/recent"
                }
                className="text-center w-fit"
            >
                <div
                    className={`font-light pb-2.5 pt-1 text-sm font-comfortaa ${
                        pathname === "/recent"
                            ? " border-b-2 border-orange-500 px-2 text-orange-500 hover:text-orange-200 hover:border-orange-200 transition-all"
                            : pathname === "/recent-photographers"
                            ? "border-b-2 border-purple-500 px-2 text-purple-500 hover:text-purple-200 hover:border-purple-200 transition-all"
                            : "border-b-2 border-transparent text-gray-500 hover:text-black hover:border-black transition-all"
                    } px-2`}
                >
                    <FontAwesomeIcon icon="clock" className={`font-thin mr-1.5`} />
                    {pathname === "/favorites/photographers" ||
                    pathname === "/photographers" ||
                    pathname === "/popular-photographers" ||
                    pathname === "/recent-photographers"
                        ? t("recentsphotographers")
                        : t("recents")}
                </div>
            </Link>
            {pathname.startsWith("/search/category/") ||
            pathname.startsWith("/search/typestyle/") ||
            pathname.startsWith("/search/license/") ||
            pathname.startsWith("/search/region/") ||
            pathname.startsWith("/search/date/") ||
            pathname === "/favorites" ||
            pathname === "/recent" ||
            pathname === "/galeries" ||
            pathname === "/popular" ? (
                <>
                    <Link to="/galeries" className="text-center w-fit">
                        <div
                            className={`font-light pb-2.5 pt-1 text-sm font-comfortaa ${
                                pathname === "/galeries"
                                    ? "text-orange-500 border-b-2 border-orange-500 hover:text-orange-200 hover:border-orange-200 transition-all"
                                    : "text-gray-500 border-b-2 border-transparent hover:text-black hover:border-black transition-all"
                            } px-2`}
                        >
                            <FontAwesomeIcon icon="images" className={`font-thin mr-1.5`} />
                            {t("galeries")}
                        </div>
                    </Link>
                    <DropDown pathname={pathname} icon="folder" from="photo" mainParam="category" title={"categories"} side="left" />
                    <DropDown pathname={pathname} icon="th" from="photo" mainParam="typestyle" title={"type"} side="left" />
                    <DropDown pathname={pathname} icon="copyright" from="photo" title={"userights"} side="right" mainParam="license" />
                </>
            ) : (
                <>
                    <DropDown pathname={pathname} icon="list-check" from="photographers" mainParam="category" title={"perfphoto"} side="left" />
                    <DropDown pathname={pathname} icon="mountain-sun" from="photographers" mainParam="photoStyle" title={"photoStyle"} side="right" />
                </>
            )}

            <Popover placement="bottom-end">
                <PopoverHandler
                    className={`${
                        pathname.startsWith("/search/region")
                            ? "text-orange-500 border-b-2 border-b-orange-500 hover:text-orange-200 hover:border-orange-200 transition-all"
                            : pathname.startsWith("/photographers/region")
                            ? "text-purple-500 border-b-2 border-b-purple-500 hover:text-purple-200 hover:border-purple-200 transition-all"
                            : "text-gray-500 hover:text-black border-b-2 border-transparent hover:border-b-black transition-all"
                    } px-2 relative inline-block text-left text-sm font-light font-comfortaa -mt-1`}
                    onClick={() => setOpen(!open)}
                >
                    <button color="white" className={`inline-flex w-full justify-center rounded-md items-center`}>
                        <FontAwesomeIcon icon="globe" className={`font-thin mr-1.5`} />
                        {t("regions")}
                        <FontAwesomeIcon icon="chevron-down" className={`ml-1.5 font-thin -mr-1 h-3 w-3`} aria-hidden="true" />
                    </button>
                </PopoverHandler>
                <PopoverContent className="z-50 w-96 relative">
                    <Map
                        disabledArea={regions}
                        from={
                            pathname.startsWith("/search/category") ||
                            pathname.startsWith("/search/typestyle") ||
                            pathname.startsWith("/search/license") ||
                            pathname.startsWith("/search/region") ||
                            pathname.startsWith("/search/date") ||
                            pathname === "/favorites" ||
                            pathname === "/recent" ||
                            pathname === "/galeries" ||
                            pathname === "/popular"
                                ? "photos"
                                : "photographers"
                        }
                        hoveredArea={hoveredArea}
                        setHoveredArea={setHoveredArea}
                    />
                    <div className="absolute bottom-2 right-2 text-lg">
                        {hoveredArea}
                    </div>
                </PopoverContent>
            </Popover>
            {(pathname.startsWith("/search/category") ||
                pathname.startsWith("/search/typestyle") ||
                pathname.startsWith("/search/license") ||
                pathname.startsWith("/search/region") ||
                pathname.startsWith("/search/date") ||
                pathname === "/favorites" ||
                pathname === "/recent" ||
                pathname === "/galeries" ||
                pathname === "/popular") && (
                <Popover placement="bottom-end">
                    <PopoverHandler
                        className={`${
                            pathname.startsWith("/search/date")
                                ? "text-orange-500 border-b-2 border-b-orange-500 hover:text-orange-200 hover:border-b-orange-200 transition-all"
                                : "border-b-2 border-transparent text-gray-500 hover:text-black hover:border-b-black transition-all"
                        } relative inline-block text-left text-sm font-light font-comfortaa -mt-1 px-2`}
                        onClick={() => setOpenDates(!openDates)}
                    >
                        <button color="white" className="inline-flex w-full justify-center rounded-md items-center font-light font-comfortaa">
                            <FontAwesomeIcon icon="calendar" className={`font-thin mr-1.5`} />
                            {t("date")}
                            <FontAwesomeIcon icon="chevron-down" className={`ml-1.5 font-thin -mr-1 h-3 w-3 `} aria-hidden="true" />
                        </button>
                    </PopoverHandler>
                    <PopoverContent className="z-50 w-96 flex">
                        <Calendar
                            onChange={(value: any) => {
                                navigate(`/search/date/${moment(value).format("YYYY-MM-DD")}`);
                            }}
                            value={pathname.startsWith("/search/date") 
                                        ? moment(pathname.split("/")[3]).format("YYYY-MM-DD")
                                        : new Date()
                                    }
                            tileClassName={tileClassName}
                            tileDisabled={({ date }) => isDateDisabled(date)}
                        />
                    </PopoverContent>
                </Popover>
            )}
        </div>
    );
}
