
export type Root = PictureType[];

export interface SearchPicturesType {
    pagination: Pagination;
    data: PictureSearch[];
}

export interface PictureSearch {
    id: string
    nsfw: boolean
    watermarkUptoDate: boolean
    canBeBought: boolean
    validationStatus: string
    portfolio: {
        id: string
        name: string
        plan: string
        isSubscribed: boolean
    }
    minPath: string
    isFavorited: boolean
    isOwned: boolean
    width: number
    height: number
}

export interface Pagination {
    current: number;
    last: number;
    totalItems: number;
    parPage: number;
}

export interface PictureType {
    id: string;
    title?: string;
    description?: string;
    originalPath?: string;
    minPath: string;
    publicPath: string;
    exifData: ExifDaum[];
    portfolio?: PortfolioOwner;
    keywords: Keyword[];
    category: Category;
    typeStyle?: TypeStyle;
    watermark: string;
    nsfw: boolean;
    identifiablePeople: boolean;
    identifiableProperties: boolean;
    writtenContract: boolean;
    license: string;
    contract?: Contract;
    galleries: Gallery[];
    portfolios: Portfolio[];
    public: boolean;
    place?: {
        id: string,
        label: string,
    };
    uploadedAt: string;
    publishedAt: string;
    favoriteCount: number;
    viewCount: number;
    isFavorited: boolean;
    watermarkUptoDate: boolean;
    canBeBought: boolean,
    canBeSold?: boolean,
    takeDate?: string,
    writtenDocuments?: string[],
    customContractPrice?: number,
    referenceContract?: string,
    watermarkType?: string,
    validationRequiredActions?: string[],
    height?: number,
    width?: number,
    canUpdateNsfw: boolean,
    canUpdateIdentifiables: boolean;
    validationStatus: string;
    isOwned: boolean;
}

export interface ExifDaum {
  key: string
  value: string
}

export interface Keyword {
  keyword?: Keyword2
  customLabel?: string
  index: number
}

export interface Keyword2 {
  enumValue: string
  frLabel: string
  enLabel: string
}

export interface Category {
  enumValue: string
  frLabel: string
  enLabel: string
}

export interface TypeStyle {
    enumValue: string;
    frLabel: string;
    enLabel: string;
}

export interface Portfolio {
  id: string
  name: string
  picture: string
  plan: string
  verified: boolean
  isSubscribed: boolean
}

export interface PortfolioOwner {
    id: string;
    name: string;
    picture: string;
    publicLink: string;
    verified: boolean
}

export interface Contract {
    id: string;
    available?: string,
    exclusive?: boolean,
    commercialUse?: boolean,
    basePrice?: number
    contract?: {
        id?: string
    }
}

export interface Gallery {
    parent: {
        id: string;
        title: string;
        publicPath: string;
        pourcentage: number;
        publishedAt: string;
    };
    index: number;
}

export const DEFAULT_EDIT_DATA = () => ({
    id: "",
    title: "",
    description: "",
    exifData: [],
    keywords: [],
    watermark: "",
    watermarkType: "",
    nsfw: false,
    canUpdateNsfw: false,
    identifiablePeople: false,
    identifiableProperties: false,
    writtenContract: false,
    license: "",
    galleries: [],
    takeDate: "",
    uploadedAt: "",
    publishedAt: "",
    width: 0,
    height: 0,
    favoriteCount: 0,
    viewCount: 0,
    writtenDocuments: [],
    canUpdateIdentifiables: false,
    publicPath: "",
    watermarkUptoDate: false,
    canBeSold: false,
    canBeBought: false,
    validationStatus: "",
    portfolio: {
        id: "",
        name: "",
        path: "",
        picture: "",
        plan: "",
        publicLink: "",
        verified: false,
        isSubscribed: false
    },
    minPath: "",
    isFavorited: false,
    isOwned: false
});

export interface PictureTypeEdit {
    id: string
    title: string
    description: string
    exifData?: ExifDaumEdit[]
    keywords?: KeywordEdit[]
    category?: CategoryEdit
    typeStyle?: TypeStyleEdit
    watermark: string
    watermarkType?: string
    nsfw: boolean
    canUpdateNsfw: boolean
    identifiablePeople: boolean
    identifiableProperties: boolean
    writtenContract?: boolean
    license: string
    contract?: ContractEdit
    galleries?: any[]
    place?: PlaceEdit
    takeDate: string
    uploadedAt: string
    publishedAt: string
    width: number
    height: number
    favoriteCount: number
    viewCount: number
    writtenDocuments: WrittenDocumentEdit[]
    canUpdateIdentifiables: boolean
    publicPath: string
    watermarkUptoDate: boolean
    canBeSold: boolean
    canBeBought: boolean
    validationStatus: string
    portfolio: PortfolioEdit
    minPath: string
    isFavorited: boolean
    isOwned: boolean
    validationRequiredActions?: string[]
}
  
export interface ExifDaumEdit {
    key: string
    value: string
}
  
export interface KeywordEdit {
    keyword: Keyword2Edit
    index: number
}
  
export interface Keyword2Edit {
    enumValue: string
    frLabel: string
    enLabel: string
}
  
export interface CategoryEdit {
    enumValue: string
    frLabel: string
    enLabel: string
}
  
export interface TypeStyleEdit {
    enumValue: string
    frLabel: string
    enLabel: string
}
  
export interface ContractEdit {
    id: string
    contract: Contract2Edit
}
  
export interface Contract2Edit {
    id: string
}
  
export interface PlaceEdit {
    id: string
    label: string
}
  
export interface WrittenDocumentEdit {
    id: string
    name: string
    mime: string
}
  
export interface PortfolioEdit {
    id: string
    name: string
    path: string
    picture: string
    plan: string
    publicLink: string
    verified: boolean
    isSubscribed: boolean
}

export interface LicencePropsEdit {
    id: string
    name: string
    available: string
    exclusive: boolean
    limited: boolean
    commercialUse: boolean
    autonomous: boolean
    branding: boolean
    forSale: boolean
    digitalModels: boolean
    print: boolean
    useByEmployerClient: boolean
    useBySubcontractor: boolean
    userCount: string
    basePrice: number
    createdAt: string
    price: number
}

export const DEFAULT_EDIT_DATA_LICENCE = () => ({
    id: "",
    name: "",
    available: "",
    exclusive: false,
    limited: false,
    commercialUse: false,
    autonomous: false,
    branding: false,
    forSale: false,
    digitalModels: false,
    print: false,
    useByEmployerClient: false,
    useBySubcontractor: false,
    userCount: "",
    basePrice: 0,
    createdAt: "",
    price: 0
})