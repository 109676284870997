import { toast } from "react-toastify";
import Cookies from 'js-cookie';

export class Storage {
    private static set(key: string, value: any) {
        localStorage.setItem(key, JSON.stringify(value));
    }

    private static get(key: string) {
        try {
            const value = localStorage.getItem(key);
            if (value) return JSON.parse(value);
            return null;
        } catch (error) {
            console.error(error)
            toast.error("Une erreur est survenue lors de la récupération de vos données");
            Storage.emptyStorage();
            window.location.href = "/";
            return null;
        }
    }

    private static remove(key: string) {
        localStorage.removeItem(key);
    }

    // static setRoles(roles: string[]) {
    //     this.set("roles", roles);
    // }

    // static getRoles(): string[] {
    //     return this.get("roles") || [];
    // }

    static setToken(token: string) {
        this.set("token", token);
    }

    static getToken(): string | null {
        return this.get("token");
    }

    static setRefreshToken(token: string) {
        this.set("refreshToken", token);
    }

    static getRefreshToken(): string | null {
        return this.get("refreshToken");
    }

    static getId(): string | null {
        return this.get("id");
    }

    static setId(id: string) {
        this.set("id", id);
    }

    static getLanguage(): string | null {
        return this.get("lang");
    }

    static setLanguage(language: string) {
        this.set("lang", language);
    }

    static getCookie(): string | undefined {
        return Cookies.get('showContent')
    }

    static setCookie() {
        Cookies.set('showContent', 'true', { expires: 1 });
    }

    static getCart(): { minPath: string, id: string, price: number }[] {
        return this.get("cart");
    }

    static setCart(cart: { minPath: string, id: string, price: number }[]) {
        this.set("cart", cart);
    }

    static clearCart() {
        this.set("cart", []);
    }

    static setToBuy(toBuy: { minPath: string, id: string, price: number }) {
        this.set("toBuy", toBuy);
    }

    static getToBuy(): { minPath: string, id: string, price: number } {
        return this.get("toBuy");
    }

    static getDelete(): boolean {
        return this.get("delete");
    }

    static setDelete() {
        this.set("delete", "TO DELETE");
    }

    static clearDelete() {
        this.remove("delete");
    }

    static emptyStorage() {
        localStorage.removeItem("token");
        localStorage.removeItem("refreshToken");
        localStorage.removeItem("id");
        localStorage.removeItem("cart");
        localStorage.removeItem("toBuy");
    }

    static clearStorage() {
        localStorage.clear();
    }
}
