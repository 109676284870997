export const Licenses = [
    {
        enumValue: "all-rights-reserved",
        frLabel: "Tous droits réservés",
        enLabel: "All rights reserved",
    },
    {
        enumValue: "own-license",
        frLabel: "Licence personnalisée",
        enLabel: "Personal license",
    },
    {
        enumValue: "cc0",
        frLabel: "CC0",
        enLabel: "CC0",
    },
    {
        enumValue: "cc-by",
        frLabel: "CC BY",
        enLabel: "CC BY",
    },
    {
        enumValue: "cc-by-sa",
        frLabel: "CC BY-SA",
        enLabel: "CC BY-SA",
    },
    {
        enumValue: "cc-by-nd",
        frLabel: "CC BY-ND",
        enLabel: "CC BY-ND",
    },
    {
        enumValue: "cc-by-nc",
        frLabel: "CC BY-NC",
        enLabel: "CC BY-NC",
    },
    {
        enumValue: "cc-by-nc-sa",
        frLabel: "CC BY-NC-SA",
        enLabel: "CC BY-NC-SA",
    },
    {
        enumValue: "cc-by-nc-nd",
        frLabel: "CC BY-NC-ND",
        enLabel: "CC BY-NC-ND",
    },
];
