
interface CountryFlagProps {
    countryCode: string; // Le code ISO alpha-3 (ex: "FRA")
    width?: number; // Largeur optionnelle du drapeau
    height?: number; // Hauteur optionnelle du drapeau
}
  
export const CountryFlag: React.FC<CountryFlagProps> = ({ countryCode, width = 32, height = 20 }) => {
    const flagSrc = `/flags/${countryCode.toLowerCase()}.svg`;
  
    return (
          <img
              src={flagSrc}
              alt={`Flag of ${countryCode}`}
              width={width}
              height={height}
              onError={(e) => {
                  e.currentTarget.src = "/flags/EMPTY.svg";
              }}
              className="-mt-0.5"
          />
    );
};