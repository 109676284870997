export const Regions = [
    {
        code: "ZH",
        enLabel: "Zurich",
        frLabel: "Zurich",
    },
    {
        code: "BE",
        enLabel: "Bern",
        frLabel: "Berne"
    },
    {
        code: "LU",
        enLabel: "Lucerne",
        frLabel: "Lucerne"
    },
    {
        code: "UR",
        enLabel: "Uri",
        frLabel: "Uri"
    },
    {
        code: "SZ",
        enLabel: "Schwyz",
        frLabel: "Schwyz"
    },
    {
        code: "OW",
        enLabel: "Obwalden",
        frLabel: "Obwald"
    },
    {
        code: "NW",
        enLabel: "Nidwalden",
        frLabel: "Nidwald"
    },
    {
        code: "GL",
        enLabel: "Glarus",
        frLabel: "Glaris"
    },
    {
        code: "ZG",
        enLabel: "Zug",
        frLabel: "Zoug"
    },
    {
        code: "FR",
        enLabel: "Fribourg",
        frLabel: "Fribourg"
    },
    {
        code: "SO",
        enLabel: "Solothurn",
        frLabel: "Soleure"
    },
    {
        code: "BS",
        enLabel: "Basel-City",
        frLabel: "Bâle-Ville"
    },
    {
        code: "BL",
        enLabel: "Basel-Landschaft",
        frLabel: "Bâle-Campagne"
    },
    {
        code: "SH",
        enLabel: "Schaffhausen",
        frLabel: "Schaffhouse"
    },
    {
        code: "AR",
        enLabel: "Appenzell Ausserrhoden",
        frLabel: "Appenzell Rhodes-Extérieures"
    },
    {
        code: "AI",
        enLabel: "Appenzell Innerrhoden",
        frLabel: "Appenzell Rhodes-Intérieures"
    },
    {
        code: "SG",
        enLabel: "St. Gallen",
        frLabel: "Saint-Gall"
    },
    {
        code: "GR",
        enLabel: "Graubünden",
        frLabel: "Grisons"
    },
    {
        code: "AG",
        enLabel: "Aargau",
        frLabel: "Argovie"
    },
    {
        code: "TG",
        enLabel: "Thurgau",
        frLabel: "Thurgovie"
    },
    {
        code: "TI",
        enLabel: "Ticino",
        frLabel: "Tessin"
    },
    {
        code: "VD",
        enLabel: "Vaud",
        frLabel: "Vaud"
    },
    {
        code: "VS",
        enLabel: "Valais",
        frLabel: "Valais"
    },
    {
        code: "NE",
        enLabel: "Neuchâtel",
        frLabel: "Neuchâtel"
    },
    {
        code: "GE",
        enLabel: "Geneva",
        frLabel: "Genève"
    },
    {
        code: "JU",
        enLabel: "Jura",
        frLabel: "Jura"
    }
]