import axios from "axios";
import { Storage } from "../storage";
import { toast } from "react-toastify";

export const refreshAuthLogic = (baseUrl: string) => async (failedRequest: { response: { config: { headers: { [x: string]: string } } } }) => {
    const oldRefreshToken = await Storage.getRefreshToken();
    const tokenRefreshResponse = await axios.post(
        baseUrl,
        { 
            refresh_token: oldRefreshToken 
        },
        {
            headers: {
                Accept: "application/json",
            },
            validateStatus: function (status) {
                if (status === 401) {
                    Storage.emptyStorage();
                    toast.error("Votre session a expiré, veuillez vous reconnecter");
                    window.location.href = "/";
                }
                return status < 400;
            },
        }
    );
    Storage.setToken(tokenRefreshResponse.data.token);
    Storage.setRefreshToken(tokenRefreshResponse.data.refresh_token);
    Storage.setId(tokenRefreshResponse.data.id);
    failedRequest.response.config.headers["Authorization"] = "Bearer " + tokenRefreshResponse.data.token;
    return Promise.resolve();
};
