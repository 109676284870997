import { useTranslation } from "react-i18next";

export default function TitleDescribe ({ title, describe} : { title: string, describe: string }) {
    const { t } = useTranslation();
    return (
        <div className="flex flex-col w-full pr-2 pb-1">
            <p className="text-lg font-sans">{t(title)}</p>
            <p className="text-sm text-gray-800">{t(describe)}</p>
        </div>
    )
}