import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Tooltip } from "@material-tailwind/react";
import { useTranslation } from "react-i18next";

export default function TitleHelper ({ title, infobulle, description } : Readonly<{ title: string, infobulle: string, description?: string }>) {
    const { t } = useTranslation();
    return (
        <div className="flex flex-col w-full pr-2 pb-1">
            <div className="flex flex-row items-center w-full pr-2 pb-1">
                <p className="text-lg font-sans">{t(title)}</p>
                <Tooltip content={t(infobulle)} placement="right" className="bg-gray-400 max-w-[60%] md:max-w-[400px]">
                    <FontAwesomeIcon icon="info-circle" className="text-orange-500 ml-2" />
                </Tooltip>
            </div>
            {description && <p className="text-sm text-gray-800">{t(description)}</p>}
        </div>
    )
}