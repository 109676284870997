export const returnTitle = (t: any,type: string) => {
    switch (type) {
        case "favorite_image":
            return t("newLikedPicture");
        case "favorite_gallery":
            return t("newLikedGallery");
        case "new_subscriber":
            return t("newSubscriber");
        case "new_testimonial":
            return t("newTestimonial");
        case "new_offer":
            return t("newoffer_only");
        case "new_appointment":
            return t("newrdv");
        case "new_message":
            return t("newMessage");
        case "picture_status":
            return t("pictureStatus");
        case "new_sale":
            return t("newSale");
        case "subscription_status":
            return t("subscriptioned");
        default:
            return t("notif_othres");
    }
}

export const returnUrl = (type: string, context: { gallery?: string, picture?: string, status?: string, author?: string, portfolio?: string, contact?: string }) => {    
    switch (type) { 
        case "favorite_image":
            return "";
        case "favorite_gallery":
            return `/gallery/${context.gallery}`;
        case "new_subscriber":
            return `/portfolio/${context.portfolio}/photos`;
        case "new_testimonial":
            return `/portfolio/${context.portfolio}/opinion`;
        case "new_offer":
            return `/contact/offer/${context.contact}`;
        case "new_appointment":
            return `/contact/appointment/${context.contact}`;
        case "new_message":
            return `/contact/message/${context.contact}`;
        case "picture_status":
            return "/sales/pictures";
        case "new_sale":
            return `/sales/history`;
        case "subscription_status":
            return `/account/subscription`;
        default:
            return "";
    }
}

export const returnSubtitle = (t: any, type: string, context: { picture?: string, status?: string, author?: string, gallery_title?: string, username?: string }) => {
    switch (type) {
        case "favorite_image":
            return t("notif_likedpic");
        case "favorite_gallery":
            return t("notif_likedgal", { gallery: context.gallery_title });
        case "new_subscriber":
            return t("notif_newsub", { user: context.username });
        case "new_testimonial":
            return t("notif_newtesti", { user: context.username });
        case "new_offer":
            return t("notif_from", { user: context.username });
        case "new_appointment":
            return t("notif_from", { user: context.username });
        case "new_message":
            return t("notif_from", { user: context.username });
        case "picture_status":
            return t("notif_newstatus", { status: context.status && t(context.status) });
        case "new_sale":
            return t("notif_newsale");
        default:
            return "";
    }
}