import LoaderEzoom from "../assets/images/elements/loader.gif"

export default function Loader({ loading } : Readonly<{ loading: boolean }>) {
    return (
        <div
            className={`fixed top-0 left-0 z-50 w-screen h-screen transition-all duration-400 ${
                loading ? "flex opacity-100" : "hidden opacity-0"
            } justify-center items-center bg-white`}
        >
            <img src={LoaderEzoom} alt="loading ezoom" width={50} />
        </div>
    );
}
