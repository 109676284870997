import { Reducer } from "@reduxjs/toolkit";

interface GalleriesProps {
    banner?: {
        minPath?: string;
    },
    bannerOffset?: number;
    gallery: string;
    index: number;
}

interface PicturesProps {
    picture: string;
}

interface PerformanceProps {
    title: string;
    description: string;
    priceType: string;
    priceValue: number;
    priceCeil: number;
    type: string;
    pictures: PicturesProps[];
    galleries: GalleriesProps[];
    bannerOffset: number;
    banner: {
        id: string;
        minPath: string;
    }
    selectorPrices: string;
    keywords: { value: string; label: string; type: string }[]
}

const initialState: PerformanceProps = {
    title: "",
    description: "",
    priceType: "ondemand",
    priceValue: 0,
    priceCeil: 0,
    type: "",
    pictures: [],
    galleries: [],
    bannerOffset: 0,
    banner: {
        id: "",
        minPath: ""
    },
    selectorPrices: "fixed",
    keywords: []
};

const performanceReducer: Reducer<PerformanceProps, any> = (state = initialState, action) => {
    switch (action.type) {
        case "UPDATE_PERF_TITLE":
            return { ...state, title: action.payload };
        case "UPDATE_PERF_DESCRIPTION":
            return { ...state, description: action.payload };
        case "UPDATE_PERF_PRICETYPE":
            return { ...state, priceType: action.payload };
        case "UPDATE_PERF_PRICEVALUE":
            return { ...state, priceValue: action.payload };
        case "UPDATE_PERF_PRICECEIL":
            return { ...state, priceCeil: action.payload };
        case "UPDATE_PERF_TYPE":
            return { ...state, type: action.payload };
        case "UPDATE_PERF_PICTURES":
            return { ...state, pictures: action.payload };
        case "UPDATE_PERF_GALLERIES":
            return { ...state, galleries: action.payload };
        case "UPDATE_PERF_BANNER":
            return { ...state, banner: action.payload };
        case "UPDATE_PERF_BANNER_OFFSET":
            return { ...state, bannerOffset: action.payload };
        case "UPDATE_PERF_SELECTORPRICES":
            return { ...state, selectorPrices: action.payload };
        case "UPDATE_PERF_KEYWORDS":
            return { ...state, keywords: action.payload };
        case "UPDATE_PERF_PERFORMANCE":
            return { ...state, ...action.payload };
        case "RESET_PERFORMANCE":
            return initialState;
        default:
            return state;
    }
};

export default performanceReducer;
