import moment, { Moment } from "moment";

export const DateDiffBetweenTwo = (startDate: Moment, endDate: Moment): string => {
    const calculateDifference = () => {
        const duration = moment.duration(endDate.diff(startDate));

        if (duration.asSeconds() < 60) {
            return (`${Math.round(duration.asSeconds())} secondes`);
        } else if (duration.asMinutes() < 60) {
            return (`${Math.round(duration.asMinutes())} minutes`);
        } else if (duration.asHours() < 24) {
            return (`${Math.round(duration.asHours())} heures`);
        } else if (duration.asDays() < 7) {
            return (`${Math.round(duration.asDays())} jours`);
        } else if (duration.asWeeks() < 4) {
            return (`${Math.round(duration.asWeeks())} semaines`);
        } else if (duration.asMonths() < 12) {
            return (`${Math.round(duration.asMonths())} mois`);
        } else {
            return (`${Math.round(duration.asYears())} années`);
        }
    };

    return calculateDifference();
};