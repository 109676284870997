import { Dialog } from "@headlessui/react";
import ModalWrapper from "./ModalWrapper";
import WatermarkedImage from "../Watermark";

import PictureOne from "../../assets/images/previ/pictureOne.jpeg";
import PictureTwo from "../../assets/images/previ/pictureTwo.webp";

export default function ModalPreview({ open, setOpen, type }: Readonly<{ open: boolean; setOpen: any; type: string }>) {
    return (
        <ModalWrapper open={open} setOpen={setOpen}>
            <Dialog.Panel className="transform overflow-hidden rounded-2xl bg-white p-6 py-12 text-left align-middle shadow-xl transition-all max-w-3xl max-h-screen">
                <div className="flex flex-row gap-8 h-full">
                    <div className="w-full h-full max-h-screen items-center flex justify-center">
                        <WatermarkedImage
                            imageUrl={PictureOne}
                            position={type === "br" ? "bottom-right" : type === "bl" ?  "bottom-left" : "center"}
                        />
                    </div>
                    <div className="w-full h-full max-h-screen items-center flex justify-center">
                        <WatermarkedImage
                            imageUrl={PictureTwo}
                            position={type === "br" ? "bottom-right" : type === "bl" ?  "bottom-left" : "center"}
                        />
                    </div>
                </div>
            </Dialog.Panel>
        </ModalWrapper>
    );
}
