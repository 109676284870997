import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Dialog, Transition } from "@headlessui/react";
import React, { Fragment, useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
import Adult from "../../../../pages/Upload/components/components/Adult";
import Description from "../../../../pages/Upload/components/components/Description";
import Filigrane from "../../../../pages/Upload/components/components/Filigrane";
import Identification from "../../../../pages/Upload/components/components/Identification";
import Keywords from "../../../../pages/Upload/components/components/Keywords";
import Licence from "../../../../pages/Upload/components/components/Licence";
import Sell from "../../../../pages/Upload/components/components/Sell";
import ContractsAPI from "../../../../services/API/Clients/ContractAPI";
import KeywordsAPI from "../../../../services/API/Clients/KeywordsAPI";
import PicturesAPI from "../../../../services/API/Clients/PicturesAPI";
import UploadAPI from "../../../../services/API/Clients/UploadAPI";
import UserAPI from "../../../../services/API/Clients/UserAPI";
import WrittenDocumentsAPI from "../../../../services/API/Clients/WrittenDocuments";
import { Storage } from "../../../../services/storage";
import { DEFAULT_EDIT_DATA, DEFAULT_EDIT_DATA_LICENCE, Keyword, LicencePropsEdit, PictureType, PictureTypeEdit } from "../../../Interfaces/PictureType";
import { handleError } from "../../../../services/Errors/handleErrors";

import DefaultPicture from "../../../../assets/images/elements/default-banner.png"
import ModalPreview from "../../../Modals/ModalPreview";

export default function PictureModalEdit({ actualId, open, onClose }: Readonly<{ actualId: string, open: boolean, onClose: (arg2?: string) => void }>) {
    const general = useSelector((state: any) => state.general);
    const userId = Storage.getId();
    const { t } = useTranslation();
    const myLang = Storage.getLanguage() ?? "fr";
    const [openPreview, setOpenPreview] = useState(false);
    const [logoFiligrane, setLogoFiligrane] = useState<File | null>(null);
    const [writtenAccords, setWrittenAccords] = React.useState<File[]>([]);
    const [fileUpload, setFileUpload] = React.useState<File | null>(null);
    const [recommandedKeywords, setRecommandedKeywords] = React.useState<{ value: string; label: string }>({ value: "", label: "" });
    const [tagInput, setTagInput] = React.useState("");
    const [openers, setOpeners] = React.useState<boolean[]>([false, false]);
    const dialogRef = useRef<HTMLDivElement>(null);
    const [otherOpeners, setOtherOpeners] = useState<boolean[]>([false, false]);
    const [dataActualItemForEdit, setDataActualItemForEdit] = useState<PictureTypeEdit>(DEFAULT_EDIT_DATA());
    const [listTags, setListTags] = React.useState<{ value: string; label: string; type: string }[]>([]);
    const [license, setLicense] = React.useState<LicencePropsEdit>(DEFAULT_EDIT_DATA_LICENCE());

    const updateThisPicture = async () => {
        if (dataActualItemForEdit?.category?.enumValue === "") {
            toast.warning(t("missingcat_type"));
            return;
        }

        if (logoFiligrane) {
            const formData = new FormData();
            formData.append("image", logoFiligrane);
            await UserAPI.uploadLogo(formData, general.me.id);
        }

        let doIdidUpload = false
        if (fileUpload) {
            const formData = new FormData();
            formData.append("image", fileUpload);
            const response = await PicturesAPI.reupload(actualId, formData);
            if (response.status === 200 || response.status === 201 || response.status === 204) {
                doIdidUpload = false
                toast.success(t("pictureReupload"));
            } else {
                handleError(response);
            }
        }

        let listOfIds: any[] = []
        if (dataActualItemForEdit.writtenContract && writtenAccords.length > 0) {
            const uploadPromises = writtenAccords.map(async (file) => {
                const formData = new FormData();
                formData.append("document", file);
                const response = await WrittenDocumentsAPI.upload(formData);
                if (response.status === 200 || response.status === 201) {
                    return response.body.id;
                } else {
                    handleError(response);
                }
            });
            const results = await Promise.all(uploadPromises);
            listOfIds = results.filter(id => id !== undefined);
        }

        const datas = {
            ...(dataActualItemForEdit.title && dataActualItemForEdit.title !== "" && { title: dataActualItemForEdit.title }),
            ...(dataActualItemForEdit.description && dataActualItemForEdit.description !== "" && { description: dataActualItemForEdit.description }),
            ...(dataActualItemForEdit.takeDate && dataActualItemForEdit.takeDate !== "" && { takeDate: dataActualItemForEdit.takeDate }),
            ...(dataActualItemForEdit.place?.id && dataActualItemForEdit.place?.id !== "" && { place: `/api/public/places/${dataActualItemForEdit.place.id}` }),
            ...(listTags.length > 0 && {
                keywords:
                    listTags.map((tag, index: number) => (tag.type === "fromCustom" ? { index: index, customLabel: tag.label } : { index: index, keyword: `/api/keywords/${tag.value}` })),
            }),
            ...(dataActualItemForEdit?.category?.enumValue && dataActualItemForEdit?.category?.enumValue !== "" && { category: `/api/categories/${dataActualItemForEdit?.category?.enumValue}`}),
            ...(dataActualItemForEdit.typeStyle && dataActualItemForEdit.typeStyle.enumValue !== "" && { typeStyle: `/api/type-styles/${dataActualItemForEdit.typeStyle.enumValue}` }),
                
            ...(dataActualItemForEdit.watermarkType !== "none" && { watermarkType: dataActualItemForEdit.watermarkType }),
            ...(dataActualItemForEdit.watermarkType !== "none" && { watermark: dataActualItemForEdit.watermark }),
            ...(dataActualItemForEdit.watermarkType === "none" && { watermark: "none" }),

            nsfw: dataActualItemForEdit.nsfw,
            identifiablePeople: dataActualItemForEdit.identifiablePeople,
            identifiableProperties: dataActualItemForEdit.identifiableProperties,
            writtenContract: dataActualItemForEdit.writtenContract,
            ...(dataActualItemForEdit.license && dataActualItemForEdit.license !== "" && { license: dataActualItemForEdit.license }),
            ...(license && license.id !== "" && !doIdidUpload && dataActualItemForEdit.license === "own-license" && { referenceContract: `/api/contracts/${license.id}` }),
            ...(license && license.id !== "" && !doIdidUpload && dataActualItemForEdit.license === "own-license" && license?.price && license?.basePrice !== license?.price && { customContractPrice: Number(license.price) }),
            ...(dataActualItemForEdit.writtenContract && listOfIds.length > 0  && { writtenDocuments: listOfIds.map((id) => `/api/written-documents/${id}`) }),
        };
        const responses = await UploadAPI.editPicture(actualId, datas);
        if (responses.status === 200 || responses.status === 201) {
            toast.success(t("pictureUpdated"));
            onClose("withReload");
        } else {
            handleError(responses);
        }
    };
    
    const handleChange = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>, index: number) => {
        const { name, value } = event.target;
        if (name === "titre") {
            setDataActualItemForEdit((prev) => {
                return {
                    ...prev,
                    title: value,
                };
            });
        } else if (name === "date") {
            setDataActualItemForEdit((prev) => {
                return {
                    ...prev,
                    takeDate: value,
                };
            });
        } else {
            setDataActualItemForEdit((prev) => {
                return {
                    ...prev,
                    [name]: value,
                };
            });
        }
    };

    const handleChangeIdentification = (name: string, value: boolean) => {
        if (name === "people") setDataActualItemForEdit({ ...dataActualItemForEdit, identifiablePeople: value });
        else if (name === "properties") setDataActualItemForEdit({ ...dataActualItemForEdit, identifiableProperties: value });
        else if (name === "writtenContract") setDataActualItemForEdit({ ...dataActualItemForEdit, writtenContract: value });
        else return;
    };

    const handleChangeDescriptionLieu = (id: string, label: string, index: number) => {
        setDataActualItemForEdit((prev) => {
            return {
                ...prev,
                place: {
                    id: id,
                    label: label,
                },
            };
        });
    };

    const handleChangeSell = (license: any) => {
        setLicense(license);
        setDataActualItemForEdit({ ...dataActualItemForEdit, license: "own-license" });
    };

    const fetchingContracts = async (id?: string) => {
        if (!id) return;
        const myId = Storage.getId();
        const response = await ContractsAPI.contracts(myId ?? "");

        if (response.status === 200) {
            setLicense(response.body.data.find((item: any) => item.id === id));
        } else {
            handleError(response);
        }
    };

    const handleChooseTag = async () => {
        if (dataActualItemForEdit?.category?.enumValue === "") return;
        if (tagInput === "") {
            setRecommandedKeywords({ value: "", label: "" });
            return;
        }
        const response = await KeywordsAPI.keywordsAutoCompleteCategory(tagInput, dataActualItemForEdit?.category?.enumValue ?? "");
        if (response.status === 200 || response.status === 204 || response.status === 201) {
            if (response.body[0] === undefined) {
                setRecommandedKeywords({ value: "", label: "" });
                return;
            }
            setRecommandedKeywords({
                value: response.body[0].enumValue,
                label: myLang === "fr" ? response.body[0].frLabel : response.body[0].enLabel,
            });
        } else {
            handleError(response);
        }
    };

    const addTag = (event: React.KeyboardEvent<HTMLInputElement>) => {
        if (event.key === "Enter") {
            const myData = {
                value: event.currentTarget.value,
                label: event.currentTarget.value,
                type: "fromCustom",
            };
            setListTags([...listTags, myData]);
            setTagInput("");
            setRecommandedKeywords({ value: "", label: "" });
        } else if (event.key === "Tab") {
            if (recommandedKeywords.value === "") return;
            const myData = {
                value: recommandedKeywords.value,
                label: recommandedKeywords.label,
                type: "fromList",
            };
            setListTags([...listTags, myData]);
            setTagInput("");
            setRecommandedKeywords({ value: "", label: "" });
        } else if (event.key === "Backspace" && tagInput === "") {
            setRecommandedKeywords({ value: "", label: "" });
            handleChooseTag();
        } else console.error("error");
    };

    const addTagToList = (values: string, labels: string) => {
        const myData = {
            value: values,
            label: labels,
            type: "fromList",
        };
        setListTags([...listTags, myData]);
    };

    const removeTag = (tag: string) => {
        setListTags(listTags.filter((item) => item.label !== tag));
    };

    useEffect(() => {
        handleChooseTag();
    }, [dataActualItemForEdit?.category?.enumValue]);

    useEffect(() => {
        if (!userId || !actualId) return;
        const fetchData = async () => {
            const response = await PicturesAPI.getPictureDetails(actualId);
            if (response.status === 200) {
                setDataActualItemForEdit({
                    id: response.body.id,
                    title: response.body.title,
                    description: response.body.description,
                    category: response.body.category,
                    typeStyle: response.body.typeStyle,
                    watermark: response.body.watermark,
                    nsfw: response.body.nsfw,
                    identifiablePeople: response.body.identifiablePeople,
                    identifiableProperties: response.body.identifiableProperties,
                    writtenContract: response.body.writtenContract,
                    license: response.body.license,
                    contract: response.body.contract,
                    galleries: response.body.galleries,
                    portfolio: response.body.portfolios,
                    place: response.body.place,
                    uploadedAt: response.body.uploadedAt,
                    publishedAt: response.body.publishedAt,
                    favoriteCount: response.body.favoriteCount,
                    viewCount: response.body.viewCount,
                    isFavorited: response.body.isFavorited,
                    watermarkUptoDate: response.body.watermarkUptoDate,
                    canBeBought: response.body.canBeBought,
                    canBeSold: response.body.canBeSold,
                    takeDate: response.body.takeDate,
                    writtenDocuments: response.body.writtenDocuments,
                    watermarkType: response.body.watermarkType,
                    height: response.body.height,
                    width: response.body.width,
                    canUpdateNsfw: response.body.canUpdateNsfw,
                    canUpdateIdentifiables: response.body.canUpdateIdentifiables,
                    validationStatus: response.body.validationStatus,
                    isOwned: response.body.isOwned,
                    exifData: response.body.exifData,
                    keywords: response.body.keywords.map((item: Keyword) => {
                        if (item.keyword)
                            return {
                                keyword: item.keyword.enumValue,
                                index: item.index,
                                type: "fromList",
                            };
                        else
                            return {
                                keyword: item.customLabel,
                                index: item.index,
                                type: "fromCustom",
                            };
                    }),
                    minPath: response.body.minPath,
                    publicPath: response.body.publicPath,
                    validationRequiredActions: response.body.validationRequiredActions,
                });
                if (response.body.contract && response.body.contract.id !== "") {
                    fetchingContracts(response.body.contract.contract   .id);
                }
                setListTags(response.body.keywords.map((item: Keyword) => {
                    if (item.keyword)
                        return {
                            value: item.keyword.enumValue,
                            label: item.keyword.frLabel,
                            index: item.index,
                            type: "fromList",
                        };
                    else
                        return {
                            value: item.customLabel,
                            label: item.customLabel,
                            index: item.index,
                            type: "fromCustom",
                        };
                }))
            } else {
                handleError(response);
            }
        }
        fetchData();
        setLogoFiligrane(null);
    }, [actualId, open]);

    useEffect(() => {
        if (openers[1] && dialogRef.current) {
            dialogRef.current.scrollBy(0, 40);
        }
    }, [openers]);

    useEffect(() => {
        setDataActualItemForEdit(DEFAULT_EDIT_DATA());
        setListTags([]);
        setLicense({
            id: "",
            name: "",
            available: "",
            exclusive: false,
            limited: false,
            commercialUse: false,
            autonomous: false,
            branding: false,
            forSale: false,
            digitalModels: false,
            print: false,
            useByEmployerClient: false,
            useBySubcontractor: false,
            userCount: "",
            basePrice: 0,
            createdAt: "",
            price: 0
        });
    }, [open]);

    const handleClosePreview = () => {
        setOpenPreview(false);
    }

    const validationRequiredActions = dataActualItemForEdit?.validationRequiredActions || [];
    const handleClose = () => onClose();
    const imageUrl = dataActualItemForEdit?.minPath || DefaultPicture;

    return (
        <>
            <ModalPreview open={openPreview} setOpen={handleClosePreview} type={dataActualItemForEdit.watermarkType ?? "bl"} />
            <Transition appear show={open} as={Fragment}>
                <Dialog as="div" className="relative z-40" onClose={handleClose}>
                    <Transition.Child
                        as={Fragment}
                        enter="ease-out duration-300"
                        enterFrom="opacity-0"
                        enterTo="opacity-100"
                        leave="ease-in duration-200"
                        leaveFrom="opacity-100"
                        leaveTo="opacity-0"
                    >
                        <div className="fixed inset-0 bg-black/25" />
                    </Transition.Child>
                    <div className="fixed inset-0 overflow-y-auto">
                        <div className="flex w-full md:w-2/5 mx-auto h-screen items-center justify-center p-4 text-left">
                            <Transition.Child
                                as={Fragment}
                                enter="ease-out duration-300"
                                enterFrom="opacity-0 scale-95"
                                enterTo="opacity-100 scale-100"
                                leave="ease-in duration-200"
                                leaveFrom="opacity-100 scale-100"
                                leaveTo="opacity-0 scale-95"
                            >
                                <Dialog.Panel className="transform overflow-y-auto transition-all flex flex-col justify-center items-center py-4 bg-gray-100  rounded-lg shadow-lg border-2 border-gray-300 w-screen lg:w-[60vw]">
                                    <FontAwesomeIcon icon="x" className="absolute top-4 right-4 text-gray-500 cursor-pointer" onClick={handleClose} />
                                    <img src={imageUrl} style={{ height: "20vh" }} className="mx-auto" />
                                    <div className="h-full overflow-y-auto my-4 px-4" style={{ height: "50vh" }}>
                                        {validationRequiredActions.length > 0 && (
                                            <Alert message={t("error_edition_need")} actions={validationRequiredActions} t={t} />
                                        )}
                                        <Description
                                            openDescription={otherOpeners[0]}
                                            toggleOpenDescription={() => setOtherOpeners([!otherOpeners[0], otherOpeners[1]])}
                                            descriptions={[
                                                {
                                                    titre: dataActualItemForEdit?.title ?? "",
                                                    description: dataActualItemForEdit?.description ?? "",
                                                    date: dataActualItemForEdit?.takeDate ?? "",
                                                    lieu: { id: dataActualItemForEdit?.place?.id ?? "", label: dataActualItemForEdit?.place?.label ?? "" },
                                                },
                                            ]}
                                            setDescriptions={() => {}}
                                            handleChange={handleChange}
                                            handleChangeDescriptionLieu={handleChangeDescriptionLieu}
                                            isIndivOrNot={true}
                                            setConfigIndivOrNot={() => {}}
                                            bulkIds={[actualId]}
                                            withOpeners={true}
                                        />
                                        <Keywords
                                            addTag={addTag}
                                            listTags={listTags}
                                            addTagToList={addTagToList}
                                            removeTag={removeTag}
                                            optionCat={dataActualItemForEdit?.category?.enumValue ?? ""}
                                            handleSelectCat={(e: { value: string }) =>
                                                setDataActualItemForEdit({
                                                    ...dataActualItemForEdit,
                                                    category: { enumValue: e.value ?? "", frLabel: "", enLabel: "" },
                                                })
                                            }
                                            optionTyp={dataActualItemForEdit?.typeStyle?.enumValue ?? ""}
                                            handleSelectTyp={(e: { value: string }) =>
                                                setDataActualItemForEdit({
                                                    ...dataActualItemForEdit,
                                                    typeStyle: { enumValue: e.value ?? "", frLabel: "", enLabel: "" },
                                                })
                                            }
                                            recommandation={recommandedKeywords}
                                            setRecommandedKeywords={setRecommandedKeywords}
                                            tagInput={tagInput}
                                            setTagInput={setTagInput}
                                        />
                                        <Filigrane
                                            handleChangeSelectFili={(e: any) => setDataActualItemForEdit({ ...dataActualItemForEdit, watermarkType: e.value })}
                                            optionChooseFili={dataActualItemForEdit.watermarkType ?? "none"}
                                            handleRadio={(e: string) => setDataActualItemForEdit({ ...dataActualItemForEdit, watermark: e })}
                                            sideChoose={dataActualItemForEdit.watermark ?? "bl"}
                                            handleModalToggle={() => {}}
                                            fileLogo={logoFiligrane}
                                            handleChangePicture={(event: React.ChangeEvent<HTMLInputElement>) =>
                                                event?.currentTarget.files && setLogoFiligrane(event?.currentTarget.files[0])
                                            }
                                            general={general}
                                            withPrevi={false}
                                        />
                                        <Adult
                                            filterAdult={dataActualItemForEdit.nsfw}
                                            disabled={dataActualItemForEdit.canUpdateNsfw}
                                            handleFilterAdult={() => setDataActualItemForEdit({ ...dataActualItemForEdit, nsfw: !dataActualItemForEdit.nsfw })}
                                        />
                                        <Identification
                                            openIdentifiable={openers[0]}
                                            toggleOpenIdentifiable={() => setOpeners([!openers[0], openers[1]])}
                                            identification={{
                                                people: dataActualItemForEdit.identifiablePeople,
                                                properties: dataActualItemForEdit.identifiableProperties,
                                                writtenContract: dataActualItemForEdit?.writtenContract ?? false,
                                            }}
                                            setIdentification={handleChangeIdentification}
                                            writtenAccords={writtenAccords}
                                            setWrittenAccords={setWrittenAccords}
                                            nbrAlreadyImported={dataActualItemForEdit?.writtenDocuments?.length ?? 0}
                                        />
                                        <Licence
                                            optionChoose={dataActualItemForEdit.license}
                                            handleChangeLicence={(e: any) => setDataActualItemForEdit({ ...dataActualItemForEdit, license: e.value })}
                                            cantChangeIt={true}
                                        />
                                        <Sell
                                            openSell={openers[1]}
                                            toggleOpenSell={() => setOpeners([openers[0], !openers[1]])}
                                            general={general}
                                            selectedLicense={license}
                                            setSelectedLicense={handleChangeSell}
                                            showAlert={!dataActualItemForEdit?.canBeSold || dataActualItemForEdit?.validationRequiredActions?.includes("upload_original")}
                                            fileUpload={fileUpload}
                                            setFileUpload={setFileUpload}
                                        />
                                    </div>
                                    <ActionButtons onSave={updateThisPicture} onCancel={handleClose} t={t} />
                                </Dialog.Panel>
                            </Transition.Child>
                        </div>
                    </div>
                </Dialog>
            </Transition>
        </>
    );
}


function Alert({ message, actions, t }: { message: string, actions: string[], t: (arg0: string) => string }) {
    return (
        <div className="flex bg-orange-50/20 text-sm text-black px-4 py-3 font-sans rounded-lg" role="alert">
            <p className="flex flex-col">
                <span dangerouslySetInnerHTML={{ __html: message }} />
                <ul>
                    {actions.map((item) => (
                        <li className="text-xs" key={item}>{t(`editionPicture${item}`)}</li>
                    ))}
                </ul>
            </p>
        </div>
    );
}

function ActionButtons({ onSave, onCancel, t }: { onSave: () => void, onCancel: () => void, t: (arg0: string) => string }) {
    return (
        <div className="flex justify-center gap-4 mt-6 bg-gray-100 w-full py-2">
            <button type="button" className="rounded-full bg-orange-400 text-white px-4 py-2 text-sm font-medium hover:shadow-xl transition-all font-sans" onClick={onSave}>
                {t("save")}
            </button>
            <button type="button" className="rounded-full border bg-white px-4 py-2 text-sm font-medium hover:shadow-xl transition-all font-sans" onClick={onCancel}>
                {t("cancel")}
            </button>
        </div>
    );
}