import APIClient from "../APIClients";

export default class UploadAPI {
    public static async publish(data: any) {
        return APIClient.ezoom.post(`/pictures-publish`, data);
    }

    public static async editPictures(data: any) {
        return APIClient.ezoom.put(`/pictures`, data);
    }

    public static async editPicture(id: string, data: any) {
        return APIClient.ezoom.put(`/pictures/${id}`, data);
    }
}
