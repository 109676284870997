import { Dialog } from "@headlessui/react";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import ModalWrapper from "../../Modals/ModalWrapper";

import { faCopyright } from "@fortawesome/free-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { toast } from "react-toastify";
import { Storage } from "../../../services/storage";
import { PictureSearch, PictureType } from "../../Interfaces/PictureType";

import FavoriteAPI from "../../../services/API/Clients/FavoriteAPI";
import PicturesAPI from "../../../services/API/Clients/PicturesAPI";

import { A11y, Navigation, Scrollbar, Keyboard } from "swiper/modules";
import { Swiper, SwiperSlide } from "swiper/react";
import { handleBuyIt } from "./components/PictureModalBuyIt";
import { handleContractIt } from "./components/PictureModalContract";
import { handleDelete } from "./components/PictureModalDelete";
import { removeNsfw } from "./components/PictureModalNsfw";
import { openReportModal } from "./components/PictureModalReport";
import PictureModalWithoutFullScreen from "./components/PictureModalWithoutFullScreen";
import { usePictureEdit } from "../../../providers/PictureEditProvider";
import CardUserWithData from "../CardUserWithData";
import { handleError } from "../../../services/Errors/handleErrors";
import { Tooltip, Typography } from "@material-tailwind/react";


export default function PictureModal({
    open,
    setOpen,
    ids,
    pictures,
    callNextPictures,
}: Readonly<{ open: boolean; setOpen: (arg: boolean) => void; ids: string; pictures: PictureSearch[]; callNextPictures?: Function }>) {
    const { t } = useTranslation();
    const [actualId, setActualId] = React.useState<string>("");
    const [loading, setLoading] = useState<boolean>(true);
    const [dataActualItem, setDataActualItem] = React.useState<PictureType>({
        id: "",
        minPath: "",
        publicPath: "",
        exifData: [],
        portfolio: {
            id: "",
            name: "",
            picture: "",
            publicLink: "",
            verified: false,
        },
        keywords: [],
        category: {
            enumValue: "",
            frLabel: "",
            enLabel: "",
        },
        watermark: "",
        nsfw: false,
        identifiablePeople: false,
        identifiableProperties: false,
        writtenContract: false,
        license: "",
        galleries: [],
        portfolios: [],
        uploadedAt: "",
        favoriteCount: 0,
        viewCount: 0,
        isFavorited: false,
        watermarkUptoDate: false,
        canBeBought: false,
        validationStatus: "",
        canUpdateIdentifiables: false,
        canUpdateNsfw: false,
        isOwned: false,
        publishedAt: "",
        public: false
    });
    const [repartition, setRepartition] = useState<{
        users: number;
        photographers: number;
    }>({
        users: 0,
        photographers: 0,
    });
    const [hover, setHover] = React.useState(false);
    const [isFullScreen, setIsFullScreen] = React.useState(false);
    const [currentPicture, setCurrentPicture] = React.useState<number>(0);
    const [printNsfw, setPrintNsfw] = React.useState(false);
    const myId = Storage.getId();

    const { editData } = usePictureEdit();

    useEffect(() => {
        const foundItem = pictures.find((item) => item.id === ids);
        if (!foundItem) return;
        const actualIndexOfItem = pictures.indexOf(foundItem);
        setActualId(ids);
        setCurrentPicture(actualIndexOfItem);
        fetchData(true, ids);
        if (Storage.getCookie() === "true") setPrintNsfw(true);
        else setPrintNsfw(false);
    }, [ids]);

    const handleChangeFav = async () => {
        if (myId) {
            const data = {
                picture: `/api/public/pictures/${dataActualItem.id}`,
                owner: `/api/users/${myId}`,
            };
            if (!dataActualItem.isFavorited) {
                const response = await FavoriteAPI.fav(data);
                if (response.status === 200 || response.status === 201) toast.success(t("addToFav"));
                else handleError(response);
            } else {
                const response = await FavoriteAPI.unFav(data);
                if (response.status === 200 || response.status === 201 || response.status === 204) toast.success(t("removeFromFav"));
                else handleError(response);
            }
            fetchData(false);
        }
    };

    const fetchData = async (isLoading: boolean, ids?: string) => {
        if (isLoading)
            setLoading(true);
        if (ids === "" || !ids) {
            if (actualId === "") {
                return;
            } else {
                await PicturesAPI.addView({ picture: `/api/public/pictures/${actualId}` });
                const response = await PicturesAPI.getPicture(actualId);
                if (response.status === 200 || response.status === 201) {
                    setDataActualItem(response.body);
                    if (response.body.isOwned) {
                        const responseDetails = await PicturesAPI.getPictureDetails(actualId);
                        if (responseDetails.status === 200 || responseDetails.status === 201) {
                            setDataActualItem(responseDetails.body);
                        } else {
                            handleError(responseDetails);
                        }
                    }
                    const responseRepartition = await PicturesAPI.getRepartition(actualId);
                    if (response.status === 200 || response.status === 201) {
                        setRepartition(responseRepartition.body);
                        setLoading(false);
                    } else {
                        handleError(responseRepartition);
                    }
                } else {
                    handleError(response);
                }
            }
        } else {
            await PicturesAPI.addView({ picture: `/api/public/pictures/${ids}` });
            const response = await PicturesAPI.getPicture(ids);
            if (response.status === 200 || response.status === 201) {
                setDataActualItem(response.body);
                if (response.body.isOwned) {
                    const responseDetails = await PicturesAPI.getPictureDetails(ids);
                    if (responseDetails.status === 200 || responseDetails.status === 201) {
                        setDataActualItem(responseDetails.body);
                    } else {
                        handleError(responseDetails);
                    }
                }
                const responseRepartition = await PicturesAPI.getRepartition(ids);
                if (response.status === 200 || response.status === 201) {
                    setRepartition(responseRepartition.body);
                    setLoading(false);
                } else {
                    handleError(responseRepartition);
                }
            } else {
                handleError(response);
            }
        }
    };

    const myCallBackFunction = (swiper: any) => {
        setLoading(true);
        const targetIndex = swiper.realIndex;
        const targetItem = pictures[targetIndex];
    
        if (!targetItem) {
            return;
        }
    
        setActualId(targetItem.id);
        if (targetIndex >= pictures.length - 5 && targetIndex < pictures.length + 1 && callNextPictures) callNextPictures();
        fetchData(true, targetItem.id);
    };    

    const enterFullScreen = () => {
        if (document.documentElement.requestFullscreen) document.documentElement.requestFullscreen();
        setIsFullScreen(true);
    };

    const exitFullScreen = () => {
        if (document.exitFullscreen) document.exitFullscreen();
        setIsFullScreen(false);
    };

    const onSaveEdit = () => {
        fetchData(true);
    };

    useEffect(() => {
        const handleKeyUp = (event: KeyboardEvent) => {
            if (event.key === "Escape" && isFullScreen) {
                exitFullScreen();
            }
        };
        document.addEventListener("keyup", handleKeyUp);
        const handleFullscreenChange = () => {
            setIsFullScreen(!!document.fullscreenElement);
        };
        document.addEventListener("fullscreenchange", handleFullscreenChange);
        document.addEventListener("mozfullscreenchange", handleFullscreenChange);
        document.addEventListener("webkitfullscreenchange", handleFullscreenChange);
        document.addEventListener("msfullscreenchange", handleFullscreenChange);
        return () => {
            document.removeEventListener("keyup", handleKeyUp);
            document.removeEventListener("fullscreenchange", handleFullscreenChange);
            document.removeEventListener("mozfullscreenchange", handleFullscreenChange);
            document.removeEventListener("webkitfullscreenchange", handleFullscreenChange);
            document.removeEventListener("msfullscreenchange", handleFullscreenChange);
        };
    }, [isFullScreen]);

    useEffect(() => {
        const handleKeyDown = (event: KeyboardEvent) => {
            if (event.key === 'ArrowTop' || event.key === 'ArrowUp') {
              setOpen(false);
            }
        };
    
        window.addEventListener('keydown', handleKeyDown);
        return () => {
          window.removeEventListener('keydown', handleKeyDown);
        };
      }, []);

    return (
        <ModalWrapper open={open} setOpen={setOpen} padding={false} persistant={editData !== undefined}>
            <Dialog.Panel className="transform overflow-hidden bg-white text-left align-middle shadow-xl transition-all min-h-screen max-h-screen h-screen overflow-y-auto max-w-screen max-w-screen w-screen">
                <button
                    className="relative w-screen bg-gray-950 flex flex-col justify-center items-center"
                    style={{ height: isFullScreen ? "100vh" : window.innerWidth > 768 ? "calc(100vh - 96px)" : "calc(100vh - 136px)" }}
                    onMouseEnter={() => setHover(true)}
                    onMouseLeave={() => setHover(false)}
                >
                    <div
                        className={`absolute z-50 top-4 min-[1550px]:top-6 left-4 min-[1550px]:left-6 opacity-100 ${isFullScreen ? "md:transition-all md:opacity-100" : hover ? "md:transition-all md:opacity-100" : "md:transition-all md:opacity-0 "
                            } flex duration-300 transition-all flex-row justify-between cursor-default`}
                    >
                        <FontAwesomeIcon
                            icon="x"
                            className="text-white z-50 text-2xl cursor-pointer"
                            onClick={() => {
                                if (isFullScreen) exitFullScreen();
                                else setOpen(false);
                            }}
                        />
                    </div>
                    <div
                        className={`absolute z-50 top-4 min-[1550px]:top-6 right-4 min-[1550px]:right-6 opacity-100 ${isFullScreen ? "md:transition-all md:opacity-100" : hover ? "md:transition-all md:opacity-100" : "md:transition-all md:opacity-0 "
                            } flex duration-300 transition-all flex-row justify-between cursor-default`}
                    >
                        <FontAwesomeIcon
                            icon="expand"
                            className="text-white text-2xl cursor-pointer"
                            onClick={() => (isFullScreen ? exitFullScreen() : enterFullScreen())}
                        />
                    </div>
                    <div 
                        className="w-screen flex gap-0 items-center justify-center cursor-default" 
                        style={{ height: isFullScreen ? "100vh" : window.innerWidth > 768 ? "calc(100vh - 100px)" : "calc(100vh - 166px)" }}
                    >
                        <Swiper
                            modules={[Navigation, Scrollbar, A11y, Keyboard]}
                            spaceBetween={50}
                            slidesPerView={1}
                            className="relative"
                            initialSlide={currentPicture}
                            navigation={hover}
                            onSwiper={(swiper: any) => myCallBackFunction(swiper)}
                            onSlideChange={(swiper: any) => myCallBackFunction(swiper)}
                            keyboard={{ enabled: true }}
                        >
                            {pictures.map((picture: any) => (
                                <SwiperSlide key={picture.id} onClick={() => picture.nsfw && !printNsfw && removeNsfw(setPrintNsfw, t)}>
                                    <div
                                        key={picture.id}
                                        className={`w-screen flex items-center justify-center  ${(picture.nsfw && !printNsfw) ? "blur-lg" : ""}`}
                                    >
                                        <div className={`w-screen ${isFullScreen && "h-[100vh]"} flex items-center justify-center`}>
                                            <div className={`${isFullScreen 
                                                                    ? picture.height > picture.width 
                                                                            ? `h-[100vh] max-h-[${picture.height}px] min-w-[50vw] max-w-[100vw]`
                                                                            : `w-[100vw] max-w-[${picture.width}px] min-h-[40vh] max-h-[100vw]`
                                                                    : `max-h-[calc(${picture.height}px - 96px)] h-[60vh] min-w-[75vw] md:min-w-[100vw] max-w-[${picture.width}px]`} relative bg-contain bg-no-repeat bg-center `}>
                                                <img 
                                                    alt={picture.image}
                                                    src={picture.id !== dataActualItem.id ? picture.minPath : dataActualItem.publicPath}
                                                    className="object-contain w-full h-full"
                                                />
                                            </div>
                                            {!loading && !dataActualItem.watermarkUptoDate && (
                                                <div className="absolute bottom-0 left-1/2 -translate-x-1/2 w-fit px-4 py-2 bg-orange-500/50 text-white text-xs sm:text-sm md:text-base font-bold">
                                                    {t("creating_watermark")}
                                                </div>
                                            )}
                                        </div>
                                    </div>
                                </SwiperSlide>
                            ))}
                        </Swiper>
                    </div>
                    {isFullScreen && (
                        <div className="absolute bottom-4 min-[1550px]:bottom-6 left-4 min-[1550px]:left-6 bg-white rounded-xl z-10">
                            <CardUserWithData data={dataActualItem?.portfolio} isVerified={dataActualItem?.portfolio?.verified ?? false} />
                        </div>
                    )}
                    {dataActualItem?.title && !isFullScreen && (
                        <p className={`hidden md:flex absolute bottom-4 min-[1550px]:bottom-6 left-4 min-[1550px]:left-6 text-xs sm:text-sm font-bold text-white text-left whitespace-nowrap overflow-hidden text-ellipsis pr-1 ${isFullScreen ? "w-[50vw]" : "w-[60vw] lg:w-[70vw] xl:w-[80vw]"}`}>
                            {dataActualItem.title}
                        </p>
                    )}
                    <div className="absolute bottom-4 min-[1550px]:bottom-6 right-4 min-[1550px]:right-6 flex flex-col justify-end items-end w-screen z-10">
                        {dataActualItem?.title && (
                            <>
                                <p className={`flex md:hidden text-xs sm:text-sm font-bold text-white text-right whitespace-nowrap overflow-hidden text-ellipsis ${isFullScreen ? "w-[80vw]" : "w-[90vw]"}`}>YYY{dataActualItem.title}</p>
                                {isFullScreen && (
                                    <p className={`hidden md:flex text-xs sm:text-sm font-bold text-white text-right justify-end whitespace-nowrap overflow-hidden text-ellipsis pr-1 w-[80vw]`}>
                                        {dataActualItem.title}
                                    </p>
                                )}
                            </>
                        )}
                        {dataActualItem?.license && (
                            <div className="flex flex-row justify-end items-center w-full">
                                <FontAwesomeIcon icon={faCopyright} className="text-xs sm:text-sm text-white mr-2" />
                                <p onClick={() => dataActualItem.license === "own-license" && handleContractIt(dataActualItem, t)} className="text-xs sm:text-sm font-bold text-white text-right">{t(dataActualItem.license)}</p>
                            </div>
                        )}
                    </div>
                    {dataActualItem.validationStatus === "pending" && dataActualItem.isOwned && (
                        <div className="absolute bottom-4 left-4 min-[1550px]:bottom-6 min-[1550px]:left-6 z-10">
                            <Tooltip 
                                placement="bottom" 
                                className="border border-blue-gray-50 bg-white px-4 py-3 shadow-xl shadow-black/10 z-40"
                                content={
                                    <div className="max-w-[320px]">
                                        <Typography
                                            variant="small"
                                            className="font-normal opacity-80 text-black"
                                        >
                                            {t("waiting_validation_d")}
                                        </Typography>
                                    </div>
                                }
                            >   
                                <div className="flex gap-1 bg-white px-2 py-1 rounded-full  items-center">
                                    <FontAwesomeIcon icon="clock" className="text-orange-500 -mt-0.5 hover:opacity-70 transition-all" />
                                    {t("waiting_validation")}
                                </div>
                            </Tooltip>
                        </div>
                    )}
                    {dataActualItem.validationStatus === "rejected" && dataActualItem.isOwned && (
                        <div className="absolute bottom-4 left-4 min-[1550px]:bottom-6 min-[1550px]:left-6 z-10">
                            <Tooltip
                                placement="bottom" 
                                className="border border-blue-gray-50 bg-white px-4 py-3 shadow-xl shadow-black/10 z-40"
                                content={
                                    <div className="max-w-[320px]">
                                        <Typography
                                            variant="small"
                                            className="font-normal opacity-80 text-black"
                                        >
                                            {t("reused_reasons")}
                                            <ul className="list-disc pl-4">
                                                {dataActualItem?.validationRequiredActions && 
                                                    dataActualItem?.validationRequiredActions.length > 0 && 
                                                    dataActualItem?.validationRequiredActions.map((action: string, index: number) => (
                                                        <li key={index}>{t("refused." + action)}</li>
                                                    ))
                                                }
                                            </ul>
                                        </Typography>
                                    </div>
                            }>
                                <div className="flex gap-1 bg-white px-2 py-1 rounded-full items-center">
                                    <FontAwesomeIcon icon="circle-xmark" className="text-indian-500 -mt-0.5 hover:opacity-70 transition-all" />
                                    {t("refused_validation")}
                                </div>
                            </Tooltip>
                        </div>
                    )}
                </button>
                {!isFullScreen && (
                    <PictureModalWithoutFullScreen
                        dataActualItem={dataActualItem}
                        repartition={repartition}
                        handleChangeFav={handleChangeFav}
                        actualId={actualId}
                        handleBuyIt={handleBuyIt}
                        handleDelete={handleDelete}
                        openReportModal={openReportModal}
                        loading={loading}
                        onSaveEdit={onSaveEdit}
                    />
                )}
            </Dialog.Panel>
        </ModalWrapper >
    );
}
