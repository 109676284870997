import { produce } from "immer";
import { Reducer } from "@reduxjs/toolkit";
import { PortfolioState } from "../../../components/Interfaces/PortfolioType";

const initialState: PortfolioState = {
    id: "",
    loading: true,
    name: "",
    path: "",
    description: "",
    published: false,
    regions: [],
    socialNetworks: [],
    body: [],
    lens: [],
    flash: [],
    locales: [],
    photoStyles: [],
    services: {
        pagination: { current: 0, last: 0, totalItems: 0, parPage: 0 },
        data: []
    },
    owner: {
        id: "",
        fullname: "",
        verified: false,
        plan: ""
    },
    hideLocality: false,
    locality: "",
    pictureCount: 0,
    verified: false,
    pictures: { pagination: { current: 0, last: 0, totalItems: 0, parPage: 0 }, data: [] },
    galleries: { pagination: { current: 0, last: 0, totalItems: 0, parPage: 0 }, data: [] },
    opinions: { pagination: { current: 0, last: 0, totalItems: 0, parPage: 0 }, data: [] },
    picture: "",
    pictureForModif: null,
    banner: "",
    backgroundForModif: { id: "", minPath: "" },
    bannerOffset: 0,
    subscriberCount: 0,
    plan: "",
    canAddTestimonial: "",
    canContact: "",
    isSubscribed: false,
    avgNote: 0,
    repartition: {
        users: 0,
        photographers: 0
    },
    features: {
        pages: {
            photos: false,
            about: false,
            services: false,
            testimonials: false,
            contact: false
        },
        contact: {
            offer: false,
            appointment: false,
            buy: false,
            contact: false
        },
        actions: {
            contact: false,
            testimonial: false
        }
    },
    createdAt: "",
    complete: {
        completionPercentage: 0,
        hasProfilePicture: false,
        hasBanner: false,
        hasPublishedPhotos: false,
        hasAbout: false,
        hasPublishedPortfolio: false
    },
    publicLink: "",
    public: false
};

const formReducer: Reducer<PortfolioState, any> = (state = initialState, action) => {
    return produce(state, draft => {
        switch (action.type) {
            case "UPDATE_NAME":
                draft.name = action.payload;
                break;
            case "UPDATE_DESCRIPTION":
                draft.description = action.payload;
                break;
            case "UPDATE_REGIONS":
                draft.regions = action.payload;
                break;
            case "UPDATE_SOCIALNETWORK":
                draft.socialNetworks = action.payload;
                break;
            case "UPDATE_BODY":
                draft.body = action.payload;
                break;
            case "UPDATE_LENS":
                draft.lens = action.payload;
                break;
            case "UPDATE_FLASH":
                draft.flash = action.payload;
                break;
            case "UPDATE_LOCALES":
                draft.locales = action.payload;
                break;
            case "UPDATE_PHOTOSTYLES":
                draft.photoStyles = action.payload;
                break;
            case "UPDATE_SERVICES":
                draft.services = action.payload;
                break;
            case "UPDATE_PICTURES":
                draft.pictures = action.payload;
                break;
            case "UPDATE_GALLERIES":
                draft.galleries = action.payload;
                break;
            case "UPDATE_OPINIONS":
                draft.opinions = action.payload;
                break;
            case "UPDATE_OPINIONS_NOTE":
                draft.avgNote = action.payload;
                break;
            case "UPDATE_PROFILPICTURE":
                draft.pictureForModif = action.payload;
                break;
            case "UPDATE_BACKGROUNDPICTURE":
                draft.backgroundForModif = action.payload;
                break;
            case "UPDATE_SUBSCRIBED":
                draft.isSubscribed = action.payload;
                break;
            case "UPDATE_REPARTITION":
                draft.repartition = action.payload;
                break;
            case "UPDATE_PORTFOLIO":
                Object.assign(draft, action.payload);
                break;
            case "UPDATE_HIDE_LOCALITY":
                draft.hideLocality = !draft.hideLocality;
                break;
            default:
                return state;
        }
    });
};

export default formReducer;
