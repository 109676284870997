import { Storage } from "../../storage";
import APIClient from "../APIClients";

const token = Storage.getToken();

export default class GalleryAPI {
    public static async galleries() {
        return APIClient.ezoom.get(`/galleries`);
    }

    public static async report(data: {}) {
        return APIClient.ezoom.post(`/gallery-reports`, data);
    }
    
    public static async galleriesOwner(id: string) {
        return APIClient.ezoom.get(`/galleries?user=${id}`);
    }

    public static async newGallerie(data: {}) {
        return APIClient.ezoom.post(`/galleries`, data);
    }

    public static async deleteGallerie(id: string) {
        return APIClient.ezoom.delete(`/galleries/${id}`);
    }

    public static async searchGalleriePagination(query: string, page: number) {
        const parameters = query ? `${query}&page=${page}` : `page=${page}`;

        if (!token)
            return APIClient.noInterceptor.get(`/public/search/galleries?${parameters}`);
        else
           return APIClient.ezoom.get(`/public/search/galleries?${parameters}`);
    }

    public static async compatibleFilters(query: string) {
        if (!token)
            return APIClient.noInterceptor.get(`/public/search/galleries/compatible-filters?${query}`);
        else
            return APIClient.ezoom.get(`/public/search/galleries/compatible-filters?${query}`);
    }

    public static async getGallerie(id: string) {
        if (!token)
            return APIClient.noInterceptor.get(`/public/galleries/${id}`);
        else
           return APIClient.ezoom.get(`/public/galleries/${id}`);
    }

    public static async getRepartition(id: string) {
        if (!token)
            return APIClient.noInterceptor.get(`/public/galleries/${id}/favorites-repartition`);
        else
           return APIClient.ezoom.get(`/public/galleries/${id}/favorites-repartition`);
    }

    public static async getGalleriePictures(id: string) {
        if (!token)
            return APIClient.noInterceptor.get(`/public/search/pictures?pagetype=gallery&gallery=${id}`);
        else
            return APIClient.ezoom.get(`/public/search/pictures?pagetype=gallery&gallery=${id}`);
    }

    public static async getGalleriePicturesPages(id: string, page: number) {
        if (!token)
            return APIClient.noInterceptor.get(`/public/search/pictures?pagetype=gallery&gallery=${id}&page=${page}`);
        else
            return APIClient.ezoom.get(`/public/search/pictures?pagetype=gallery&gallery=${id}&page=${page}`);
    }


    public static async putGallerie(id: string, data: {}) {
        return APIClient.ezoom.put(`/galleries/${id}`, data);
    }

    public static async publish(id: string) {
        return APIClient.ezoom.put(`/galleries/${id}/publish`, {});
    }

    public static async addView(data: {}) {
        if (!token)
            return APIClient.noInterceptor.post(`/public/gallery-views`, data);
        else
            return APIClient.ezoom.post("/public/gallery-views", data)
    }
}
