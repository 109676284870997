import DefaultPictureProfil from "../../../assets/images/elements/default-profile.png";
import { CountryFlag } from "../../CountryFlags";

export default function Avatar({ portfolio } : Readonly<{ portfolio: {
    country: string,
    id: string,
    name: string,
    picture: string,
    region: string
}}>) {

    return (
        <a className="mx-2 w-fit min-w-[100px]" href={`/portfolio/${portfolio.id}/photos`}>
            <div className="flex flex-row flex-nowrap overflow-hidden items-center py-1 px-1 bg-gray-200 hover:bg-gray-100 transition-all rounded-full">
                <div className="h-10 w-10 aspect-square rounded-full">
                    <img src={portfolio?.picture ?? DefaultPictureProfil} alt={portfolio.name} className="object-cover w-full h-full min-w-[40px] rounded-full" />
                </div>
                <div className="flex flex-col justify-center ml-4 w-fit">
                    <p className="text-base font-medium text-gray-900 whitespace-nowrap">{portfolio.name}</p>
                    {portfolio?.region && (
                        <p className="text-xs font-medium text-gray-900 whitespace-nowrap flex gap-1 items-center">
                            <CountryFlag countryCode={portfolio.country} width={15} height={15} />
                            {portfolio.region}
                        </p>
                    )}
                </div>
            </div>
        </a>
    );
}
