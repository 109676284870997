import AsyncSelect from 'react-select/async';
import PlaceAPI from '../../../services/API/Clients/PlaceAPI';
import { useTranslation } from 'react-i18next';
import { PlaceCustomStyle } from '../../CustomStyles/PlaceCustomStyle';
import { PlaceCustomStyleBorder } from '../../CustomStyles/PlaceCustomStyle';
import { handleError } from '../../../services/Errors/handleErrors';
import { useEffect } from 'react';

export default function({ uuid, onSelect, disabled = false, title = "tplace", value, whichStyle = "default" } : { uuid: string, onSelect: (value: string, value2?: string) => void, disabled?: boolean, title?: string, value?: { id: string, label: string }, whichStyle?: string }) {
    const { t } = useTranslation();

    const loadOptions = async (inputValue: string) => {
        if (uuid === "") return;
        if (inputValue.length === 0) return;
        const response = await PlaceAPI.place(uuid, inputValue)
        if (response.status === 200) {
            return response.body.map((place: any) => {
                return {
                    value: place.id,
                    label: place.label
                }
            })
        } else {
            handleError(response);
        }
    }

    const handleChange = async (value: any) => {
        const data = {
            refId: value.value
        }

        const resp = await PlaceAPI.newPlace(uuid, data)
        if (resp.status === 200 || resp.status === 201)
            onSelect(resp.body.id, resp.body.label)
        else
            handleError(resp);
    }

    return (
        <AsyncSelect 
            cacheOptions 
            loadOptions={loadOptions} 
            defaultOptions 
            menuPortalTarget={document.body}
            menuPosition="fixed"
            styles={whichStyle === "default" ? PlaceCustomStyle : PlaceCustomStyleBorder}
            className="w-full relative top-0 bg-white border-orange-500"
            placeholder={t(title)}
            onChange={handleChange}
            isDisabled={disabled}
            value={value && value.id !== "" && { value: value.id, label: value.label }}
            name="lieu"
            noOptionsMessage={() => t("noplaces")}
        />
    )
}