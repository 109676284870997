import APIClient from "../APIClients";

export default class FavoriteAPI {
    public static async fav(data: {}) {
        return APIClient.ezoom.post(`/favorites`, data);
    }

    public static async unFav(data: {}) {
        return APIClient.ezoom.post("/unfavorite", data);
    }

    public static async favGallerie(data: {}) {
        return APIClient.ezoom.post(`/favorite-galleries`, data);
    }

    public static async unFavGallerie(data: {}) {
        return APIClient.ezoom.post("/unfavorite-gallery", data);
    }

    public static async pictures() {
        return APIClient.ezoom.get(`/public/search/pictures?pagetype=favorites&favorites=true`);
    }

    public static async picturesPagination(page: number) {
        return APIClient.ezoom.get(`/public/search/pictures?pagetype=favorites&favorites=true&page=${page}`);
    }

    public static async portfolios() {
        return APIClient.ezoom.get(`/public/search/portfolios?pagetype=favorites&favorites=true`);
    }

    public static async portfoliosPagination(page: number) {
        return APIClient.ezoom.get(`/public/search/portfolios?pagetype=favorites&favorites=true&page=${page}`);
    }

    public static async galleries() {
        return APIClient.ezoom.get(`/public/search/galleries?favorites=true`);
    }

    public static async galleriesPagination(page: number) {
        return APIClient.ezoom.get(`/public/search/galleries?favorites=true&page=${page}`);
    }
}
