import { Storage } from "../../storage";
import APIClient from "../APIClients";

const token = Storage.getToken();

export default class CatsAPI {
    public static async publicCats() {
        if (token)
            return APIClient.ezoom.get(`/public/categories`)
        else
            return APIClient.noInterceptor.get(`/public/categories`)
    }

    public static async publicCatsMostUsed() {
        if (token)
            return APIClient.ezoom.get(`/public/most-used-categories`)
        else
            return APIClient.noInterceptor.get(`/public/most-used-categories`)
    }

    public static async newCat(data: {}) {
        return APIClient.ezoom.post(`/categories`, data);
    }

    public static async getCat(enumValue: string) {
        return APIClient.ezoom.get(`/categories/${enumValue}`);
    }

    public static async putCat(data: {}, enumValue: string) {
        return APIClient.ezoom.put(`/categories/${enumValue}`, data);
    }

    public static async deleteCat(enumValue: string) {
        return APIClient.ezoom.delete(`/categories/${enumValue}`);
    }
}
