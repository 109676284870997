import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import LanguageDetector from "i18next-browser-languagedetector";
import { Language } from "./enums/Language";
import translationEN from "./i18n/en.json";
import translationFR from "./i18n/fr.json";
import translationIT from "./i18n/it.json";
import translationDE from "./i18n/de.json";
import { Storage } from "./services/storage";

let defaultLanguage = Storage.getLanguage() ?? Language.FR;

// the translations
const resources = {
    en: {
        translation: translationEN,
    },
    fr: {
        translation: translationFR,
    },
    it: {
        translation: translationIT,
    },
    de: {
        translation: translationDE,
    },
};

i18n.use(LanguageDetector)
    .use(initReactI18next) 
    .init({
        fallbackLng: 'fr',
        resources,
        lng: defaultLanguage,

        keySeparator: ".", // to support nested translations

        interpolation: {
            escapeValue: false, // react already safes from xss
        },
    });

export default i18n;
