import { Storage } from "../../storage";
import APIClient from "../APIClients";

const token = Storage.getToken()

export default class PicturesAPI {
    public static async pellicle(id: string) {
        return APIClient.ezoom.get(`/pellicle?user=${id}`);
    }

    public static async pictures() {
        return APIClient.ezoom.get(`/pictures`);
    }

    public static async putPictures(data: {}) {
        return APIClient.ezoom.put(`/pictures`, data);
    }

    public static async publish(data: {}) {
        return APIClient.ezoom.post(`/pictures-publish`, data);
    }

    public static async putPicture(id: string, data: {}) {
        return APIClient.ezoom.put(`/pictures/${id}`, data);
    }

    public static async deletePicture(id: string) {
        return APIClient.ezoom.delete(`/pictures/${id}`);
    }

    public static async publishPictures(id: string, data: {}) {
        return APIClient.ezoom.put(`/pictures/${id}/publish`, data);
    }

    public static async reupload(id: string, data: FormData) {
        return APIClient.ezoom.postPicture(`/pictures/${id}/upload-original`, data);
    }

    public static async getPicture(id: string) {
        if (!token)
            return APIClient.noInterceptor.get(`/public/pictures/${id}`);
        else
            return APIClient.ezoom.get(`/public/pictures/${id}`);
    }

    public static async getPictureDetails(id: string) {
        return APIClient.ezoom.get(`/pictures/${id}`);
    }

    public static async getRepartition(id: string) {
        if (!token)
            return APIClient.noInterceptor.get(`/public/pictures/${id}/favorites-repartition`);
        else
            return APIClient.ezoom.get(`/public/pictures/${id}/favorites-repartition`);
    }

    public static async addView(body: {}) {
        if (!token)
            return APIClient.noInterceptor.post(`/public/picture-views`, body);
        else
            return APIClient.ezoom.post(`/public/picture-views`, body);
    }

 
    public static async searchPicturesPagination(query: string, page: number) {
        const parameters = query ? `${query}&page=${page}` : `page=${page}`;
        if (!token)
            return APIClient.noInterceptor.get(`/public/search/pictures?${parameters}`);
        else
            return APIClient.ezoom.get(`/public/search/pictures?${parameters}`);
    }

    public static async compatibleFilters(query: string) {
        if (!token)
            return APIClient.noInterceptor.get(`/public/search/pictures/compatible-filters?${query}`);
        else
            return APIClient.ezoom.get(`/public/search/pictures/compatible-filters?${query}`);
    }

    public static async licensesMostUsed() {
        if (token)
            return APIClient.ezoom.get(`/public/most-used-licenses`);
        else
            return APIClient.noInterceptor.get(`/public/most-used-licenses`)
    }

    public static async datesMostUsed() {
        if (token)
            return APIClient.ezoom.get(`/public/most-used-dates`);
        else
            return APIClient.noInterceptor.get(`/public/most-used-dates`)
    }

    public static async uploadPicture(data: any) {
        return APIClient.ezoom.postPicture(`/upload`, data);
    }

    public static async pictureView(data: {}) {
        return APIClient.ezoom.post(`/picture-view`, data);
    }

    public static async deletePictures(data: {}) {
        return APIClient.ezoom.post(`/delete-pictures`, data);
    }

    public static async downloadPicture(id: string) {
        return APIClient.ezoom.get(`/pictures/${id}/download-link`);
    }

    public static async getFeed() {
        if (!token)
            return APIClient.noInterceptor.get(`/public/search/pictures?pagetype=feed&from_followed=true`);
        else
            return APIClient.ezoom.get(`/public/search/pictures?pagetype=feed&from_followed=true`);
    }

    public static async getFeedPagination(page: number) {
        if (!token)
            return APIClient.noInterceptor.get(`/public/search/pictures?pagetype=feed&from_followed=true&page=${page}`);
        else
            return APIClient.ezoom.get(`/public/search/pictures?pagetype=feed&from_followed=true&page=${page}`);
    }

    public static async reportPicture(data: {}) {
        return APIClient.ezoom.post(`/picture-reports`, data);
    }

    public static async backgroundPicture() {
        return APIClient.noInterceptor.get(`/public/background-picture`);
    }

    public static async autocomplete(query: string) {
        if (!token)
            return APIClient.noInterceptor.get(`/public/search/pictures/autocomplete?query=${query}`);
        else
            return APIClient.ezoom.get(`/public/search/pictures/autocomplete?query=${query}`);
    }
}
