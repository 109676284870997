import React, { ReactNode, createContext, useContext, useMemo, useState } from 'react';
import PictureModalEdit from '../components/Box/Photo/components/PictureModalEdit';

interface EditPictureData {
    id: string;
    onSaveEdit?: () => void;
}

interface PictureEditContextType {
    editData: EditPictureData | undefined;
    setEditedPicture: React.Dispatch<React.SetStateAction<EditPictureData | undefined>>;
}

const PictureEditContext = createContext<PictureEditContextType | undefined>(undefined);

export const PictureEditProvider: React.FC<{ children: ReactNode }> = ({ children }) => {
    const [editData, setEditedPicture] = useState<EditPictureData | undefined>(undefined);

    const values = useMemo(() => ({ editData, setEditedPicture }), [editData, setEditedPicture]);

    const closeModal = (arg?: string) => {
        setEditedPicture(undefined);
        if (arg === "withReload" && editData?.onSaveEdit) {
            editData.onSaveEdit();
        }
    }

    return (
        <PictureEditContext.Provider value={values}>
            <PictureModalEdit open={editData !== undefined} onClose={closeModal} actualId={editData?.id ?? ""}/>
            {children}
        </PictureEditContext.Provider>
    );
};

// Custom hook to consume the context
export const usePictureEdit = () => {
    const context = useContext(PictureEditContext);
    if (!context) {
        throw new Error('usePictureEdit must be used within a PictureEditProvider');
    }
    return context;
};