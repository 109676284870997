import { Reducer } from "@reduxjs/toolkit";
import { PerformanceType } from "../../../components/Interfaces/PerformanceType";

const initialState: PerformanceType = {
    id: "",
    banner: {
        minPath: "",
        width: 0,
        height: 0
    },
    bannerOffset: 0,
    title: "",
    description: "",
    priceType: "ondemand",
    priceValue: 0,
    priceCeil: 0,
    portfolio: {
        id: "",
        name: "",
        plan: "",
        publicLink: "",
        isSubscribed: false,
        picture: ""
    },
    type: {
        enumValue: "",
        frLabel: "",
        enLabel: ""
    },
    pictures: [],
    galleries: [],
    published: false,
    createdAt: "",
    keywords: []
};

const performanceReducerEdit: Reducer<PerformanceType, any> = (state = initialState, action) => {
    switch (action.type) {
        case "UPDATE_PE_TITLE":
            return { ...state, title: action.payload };
        case "UPDATE_PE_DESCRIPTION":
            return { ...state, description: action.payload };
        case "UPDATE_PE_PRICETYPE":
            return { ...state, priceType: action.payload };
        case "UPDATE_PE_PRICEVALUE":
            return { ...state, priceValue: action.payload };
        case "UPDATE_PE_PRICECEIL":
            return { ...state, priceCeil: action.payload };
        case "UPDATE_PE_TYPE":
            return { ...state, type: action.payload };
        case "UPDATE_PE_PICTURES":
            return { ...state, pictures: action.payload };
        case "UPDATE_PE_GALLERIES":
            return { ...state, galleries: action.payload };
        case "UPDATE_PE_BANNER":
            return { ...state, banner: action.payload };
        case "UPDATE_PE_BANNER_OFFSET":
            return { ...state, bannerOffset: action.payload };
        case "UPDATE_PE_PORTFOLIO":
            return { ...state, portfolio: action.payload };
        case "UPDATE_PE_PUBLISHED":
            return { ...state, published: action.payload };
        case "UPDATE_PE_CREATEDAT":
            return { ...state, createdAt: action.payload };
        case "UPDATE_PE_KEYWORDS":
            return { ...state, keywords: action.payload };
        case "UPDATE_PE_PERFORMANCES":
            return { ...state, ...action.payload };
        case "RESET_PE_PERFORMANCE":
            return initialState;
        default:
            return state;
    }
};

export default performanceReducerEdit;
