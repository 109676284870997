import React, { ReactNode, createContext, useContext, useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import Panier from '../components/Panier';
import { Storage } from '../services/storage';

interface CartItem {
    id: string,
    minPath: string,
    price: number
}

interface CartContextType {
    addToCart: (item: CartItem) => void;
}

const CartContext = createContext<CartContextType | undefined>(undefined);

export const CartProvider: React.FC<{ children: ReactNode }> = ({ children }) => {
    const [itemsInCart, setItemsInCart] = React.useState<CartItem[]>([]);
    const { t } = useTranslation();

    const getItemsInCart = () => {
        const items = Storage.getCart();
        if (items) {
            setItemsInCart(items);
        }
    }

    useEffect(() => {
        getItemsInCart();
    }, [])

    const addToCart = (item: CartItem) => {
        if (itemsInCart.some(i => i.id === item.id)) {
            toast.error(t('alreadyInCart'));
            return;
        }
        const newItems = [...itemsInCart, item];
        Storage.setCart(newItems);
        toast.success(t("addedToCart"));
        getItemsInCart();
    }

    const removeFromCart = (id: string) => {
        const newItems = itemsInCart.filter(item => item.id !== id);
        Storage.setCart(newItems);
        getItemsInCart();
    }

    const clearCart = () => {
        Storage.clearCart();
        getItemsInCart();
    }


    const values = useMemo(() => ({ addToCart }), [addToCart]);


    return (
        <CartContext.Provider value={values}>
            <Panier itemsInCart={itemsInCart} removeFromCart={removeFromCart} clearCart={clearCart} />
            {children}
        </CartContext.Provider>
    );
};

export const useCart = () => {
    const context = useContext(CartContext);
    if (!context) {
        throw new Error('useCart must be used within a CartProvider');
    }
    return context;
};