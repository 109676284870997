import { Dialog, Transition } from "@headlessui/react";
import { Fragment, useEffect, useRef } from "react";

export default function ModalWrapper({
    children,
    open,
    setOpen,
    padding = true,
    persistant = false,
}: Readonly<{ children: React.ReactNode; open: boolean; setOpen: (arg: boolean) => void; padding?: boolean, persistant?: boolean }>) {

    return (
        <Transition appear show={open} as={Fragment}>
            <Dialog as="div" className="relative z-40" onClose={persistant ? () => { } : setOpen}>
                <Transition.Child
                    as={Fragment}
                    enter="ease-out duration-300"
                    enterFrom="opacity-0"
                    enterTo="opacity-100"
                    leave="ease-in duration-200"
                    leaveFrom="opacity-100"
                    leaveTo="opacity-0"
                >
                    <div className="fixed inset-0 bg-black bg-opacity-25" />
                </Transition.Child>

                <div className="fixed inset-0 overflow-y-auto">
                    <div className={`flex min-h-full h-screen items-center justify-center ${padding ? "p-4" : "p-0"} text-center`}>
                        <Transition.Child
                            as={Fragment}
                            enter="ease-out duration-300"
                            enterFrom="opacity-0 scale-95"
                            enterTo="opacity-100 scale-100"
                            leave="ease-in duration-200"
                            leaveFrom="opacity-100 scale-100"
                            leaveTo="opacity-0 scale-95"
                        >
                            {children}
                        </Transition.Child>
                    </div>
                </div>
            </Dialog>
        </Transition>
    );
}
