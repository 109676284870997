import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Avatar, Badge, Popover, PopoverContent, PopoverHandler, Typography } from "@material-tailwind/react";
import { useTranslation } from "react-i18next";
import { Storage } from "../../../services/storage";
import React, { useEffect } from "react";
import DefaultProfile from "../../../assets/images/elements/default-profile.png";
import { Link } from "react-router-dom";
import UserAPI from "../../../services/API/Clients/UserAPI";
import NotificationsMobil from "./NotificationsMobil";
import { handleError } from "../../../services/Errors/handleErrors";
import { useSelector } from "react-redux";

export default function MenuProfil() {
    const portfolio = useSelector((state: any) => state.general);
    const { t } = useTranslation();
    const signin = Storage.getToken() === null;
    const [openNotification, setOpenNotification] = React.useState(false);
    const [count, setCount] = React.useState<{count: number}>({
        count: 0
    });

    useEffect(() => {
        const fetchData = async () => {
            const response = await UserAPI.getNotificationsCount()
            if (response.status === 200) {
                setCount(response.body);
            } else {
                handleError(response);
            }
        }
        fetchData();
    }, []);

    return (
        <Popover
            placement="bottom"
            animate={{
                mount: { opacity: "1", scale: "1" },
                unmount: { opacity: "0", scale: "0.8" },
            }}
        >
            <PopoverHandler>
                <div className="w-fit h-fit">
                    {count.count > 0 ? (
                        <Badge content={count.count > 9 ? "9+" : count.count} color="orange" withBorder className="text-[8px] min-h-[20px] min-w-[20px] p-0 translate-x-1/3 -translate-y-1/3">
                            <div className="w-[40px] h-[40px] rounded-full">
                                <img
                                    alt={(portfolio && portfolio.me && portfolio.me && portfolio.me.lastname) ?? "Anonymous"}
                                    src={(portfolio && portfolio.me && portfolio.me.portfolio && portfolio.me.portfolio?.picture) ?? DefaultProfile}
                                    className="h-full w-full object-center object-cover rounded-full border-2 border-transparent hover:border-orange-500"
                                />
                            </div>
                        </Badge>
                    ) : (
                        <div className="w-[40px] h-[40px] rounded-full">
                            <img
                                alt={(portfolio && portfolio.me && portfolio.me && portfolio.me.lastname) ?? "Anonymous"}
                                src={(portfolio && portfolio.me && portfolio.me.portfolio && portfolio.me.portfolio?.picture) ?? DefaultProfile}
                                className="h-full w-full object-center object-cover rounded-full border-2 border-transparent hover:border-orange-500"
                            />
                        </div>
                    )}
                </div>
            </PopoverHandler>
            <PopoverContent
                className={`md:hidden z-40 absolute rounded-none top-0 left-0 flex-col px-4 py-2 w-full h-[calc(100vh-50px)] overflow-y-auto bg-white focus:outline-none font-thin`}
            >
                {!openNotification ? (
                    <div className={`py-1 overflow-y-auto`}>
                        {signin ? (
                            <>
                                <h2 className="text-sm font-bold text-left text-gray-700">{t("noYetSubscribe")}</h2>
                                <div className="flex flex-row items-center justify-between text-center gap-2 my-2">
                                    <Link
                                        className={`w-1/2 border-2 border-transparent bg-gray-200/30 hover:bg-white py-1 px-4 rounded-full text-black hover:shadow-lg transition-all text-center whitespace-nowrap cursor-pointer backdrop-blur-sm flex justify-center`}
                                        to="/signin"
                                    >
                                        <span className="flex">{t("signin")}</span>
                                    </Link>
                                    <Link
                                        to="/signup"
                                        className="w-1/2 border-2 border-transparent bg-orange-500 hover:bg-orange-200 py-1 px-4 rounded-full text-white hover:text-black transition-all text-center whitespace-nowrap cursor-pointer flex justify-center"
                                    >
                                        <span className="flex">{t("signup")}</span>
                                    </Link>
                                </div>
                            </>
                        ) : (
                            <button
                                type="button"
                                onClick={() => setOpenNotification(!openNotification)}
                                className="my-2 hover:bg-gradient-to-r hover:from-orange-500 hover:to-orange-100 hover:text-white text-gray-500 p-2 text-sm w-full text-left rounded-lg flex justify-between items-center focus:ring-0"
                            >
                                <div className="flex flex-row items-center">
                                    <FontAwesomeIcon icon="bell" className="mr-2" />
                                    {t("notification")}
                                    <div className={`ml-2 px-2 py-0.5 ${count.count > 0 ? "bg-orange-500" : "bg-gray-500"} rounded-full text-white text-xs`}>{count.count}</div>
                                </div>
                                <FontAwesomeIcon icon="chevron-right" />
                            </button>
                        )}
                        <hr className="my-2" />
                        <Link to="/my-portfolio">
                            <button className="my-2 hover:bg-gradient-to-r hover:from-orange-500 hover:to-orange-100 hover:text-white text-gray-500 p-2 text-sm w-full text-left rounded-lg">
                                <FontAwesomeIcon icon="images" className="mr-2" />
                                {t("myportfolio")}
                            </button>
                        </Link>
                        <Link to="/upload">
                            <button className="my-2 hover:bg-gradient-to-r hover:from-orange-500 hover:to-orange-100 hover:text-white text-gray-500 p-2 text-sm w-full text-left rounded-lg">
                                <FontAwesomeIcon icon="plus" className="mr-2" />
                                {t("myphotos")}
                            </button>
                        </Link>
                        <Link to="/favorites">
                            <button className="my-2 hover:bg-gradient-to-r hover:from-orange-500 hover:to-orange-100 hover:text-white text-gray-500 p-2 text-sm w-full text-left rounded-lg">
                                <FontAwesomeIcon icon="heart" className="mr-2" />
                                {t("myfavs")}
                            </button>
                        </Link>
                        <Link to="/sales">
                            <button className="my-2 hover:bg-gradient-to-r hover:from-orange-500 hover:to-orange-100 hover:text-white text-gray-500 p-2 text-sm w-full text-left rounded-lg">
                                <FontAwesomeIcon icon="money-bill" className="mr-2" />
                                {t("sales")}
                            </button>
                        </Link>
                        <Link to="/purchases">
                            <button className="my-2 hover:bg-gradient-to-r hover:from-orange-500 hover:to-orange-100 hover:text-white text-gray-500 p-2 text-sm w-full text-left rounded-lg">
                                <FontAwesomeIcon icon="basket-shopping" className="mr-2" />
                                {t("purchases")}
                            </button>
                        </Link>
                        <Link to="/statistics">
                            <button className="my-2 hover:bg-gradient-to-r hover:from-orange-500 hover:to-orange-100 hover:text-white text-gray-500 p-2 text-sm w-full text-left rounded-lg">
                                <FontAwesomeIcon icon="chart-simple" className="mr-2" />
                                {t("statistics")}
                            </button>
                        </Link>
                        <hr className="my-2" />
                        <Link to="/account/data">
                            <button className="my-2 hover:bg-gradient-to-r hover:from-orange-500 hover:to-orange-100 hover:text-white text-gray-500 p-2 text-sm w-full text-left rounded-lg">
                                <FontAwesomeIcon icon="gear" className="mr-2" />
                                {t("settings")}
                            </button>
                        </Link>
                        <button
                            className="my-2 hover:bg-gradient-to-r hover:from-orange-500 hover:to-orange-100 hover:text-white text-gray-500 p-2 text-sm w-full text-left rounded-lg"
                            onClick={() => {
                                localStorage.removeItem("token");
                                window.location.href = ("/");
                            }}
                        >
                            <FontAwesomeIcon icon="right-from-bracket" className="mr-2" />
                            {t("disconnect")}
                        </button>
                    </div>
                ) : (
                    <NotificationsMobil isOpen={openNotification} setOpen={setOpenNotification} side="left" />
                )}
            </PopoverContent>
        </Popover>
    );
}
