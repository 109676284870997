export default function TitlePage({ children, mt = 8, mb = 2 }: Readonly<{ children: React.ReactNode, mt?: number, mb?: number }>) {
    return (
        <div className={`w-2/3 md:w-full mt-${mt} md:mt-6 mb-${mb} md:mb-6  
            bg-white
            max-w-7xl md:mx-auto
            px-3.5 md:px-3
        `}> 
            {/** md:px-8 px-2 */}
            <h1 className="text-2xl md:text-4xl w-full max-w-7xl mx-auto">{children}</h1>
        </div>
    );
}
