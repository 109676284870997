import { Reducer } from "@reduxjs/toolkit";
import { CompatibleFilterList, FilterList, FilterSchema } from "../../../components/SearchResult/types/global";

export interface FilterStoreInterface {
    filterSchema: FilterSchema<FilterList>;
    currentFilters?: FilterList;
    compatibleFilters?: CompatibleFilterList;
    isMobileDrawerOpen: boolean;
    sortBy?: string;
}

const initialState: FilterStoreInterface = {
    filterSchema: {},
    currentFilters: undefined,
    compatibleFilters: undefined,
    isMobileDrawerOpen: false,
};

const filtersReducer: Reducer<FilterStoreInterface, any> = (state = initialState, action) => {
    switch (action.type) {
        case "INIT_FILTERS":
            return {
                ...initialState,
                filterSchema: action.payload.filterSchema,
                currentFilters: action.payload.current,
                sortBy: action.payload.defaultSort,
            };
        case "ADD_FILTER": {
            const currentFilters = { ...state.currentFilters };
            currentFilters[action.payload.filter] = action.payload.value;
            return {
                ...state,
                currentFilters,
            };
        }
        case "REMOVE_FILTER": {
            const currentFilters = { ...state.currentFilters };
            delete currentFilters[action.payload.filter];
            return {
                ...state,
                currentFilters,
            };
        }
        case "SORT_BY": {
            return {
                ...state,
                sortBy: action.payload.sortBy,
            };
        }
        case "SET_COMPATIBLE_FILTERS":
            return {
                ...state,
                compatibleFilters: action.payload.filters,
            };
        case "CLEAR_FILTERS":
            return {
                ...state,
                currentFilters: {},
            };
        case "OPEN_MOBILE_DRAWER":
            return {
                ...state,
                isMobileDrawerOpen: true,
            };
        case "CLOSE_MOBILE_DRAWER":
            return {
                ...state,
                isMobileDrawerOpen: false,
            };
        default:
            return state;
    }
};

export default filtersReducer;
