import { Storage } from "../../storage";
import APIClient from "../APIClients";

const token = Storage.getToken()

export default class PhotoStyleAPI {
    public static async styles() {
        if (token)
            return APIClient.ezoom.get(`/public/photo-styles`);
        else
            return APIClient.noInterceptor.get(`/public/photo-styles`)
    }
}
