import { Storage } from "../../storage";
import APIClient from "../APIClients";

const token = Storage.getToken();

export default class NewsletterAPI {
    public static async news() {
        if (!token)
            return APIClient.noInterceptor.get(`/public/newsletter`);
        else
            return APIClient.ezoom.get(`/public/newsletter`);
    }

    public static async subscribe(data: {}) {
        if (!token)
            return APIClient.noInterceptor.post(`/public/newsletter`, data);
        else
            return APIClient.ezoom.post(`/public/newsletter`, data);
    }

    public static async unSubscribe(enumValue: string) {
        if (!token)
            return APIClient.noInterceptor.delete(`/public/newsletter/${enumValue}`);
        else
            return APIClient.ezoom.delete(`/public/newsletter/${enumValue}`);
    }
}