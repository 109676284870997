import { Storage } from "../../storage";
import APIClient from "../APIClients";

const token = Storage.getToken();

export default class PlaceAPI {
    public static async place(sessionId: string, place: string) {
        if (!token)
            return APIClient.noInterceptor.get(`/public/autocomplete-place?input=${place}&session_id=${sessionId}`);
        else
            return APIClient.ezoom.get(`/public/autocomplete-place?input=${place}&session_id=${sessionId}`);
    }

    public static async newPlace(sessionId: string, data: {}) {
        if (!token)
            return APIClient.noInterceptor.post(`/public/places?session_id=${sessionId}`, data);
        else
            return APIClient.ezoom.post(`/public/places?session_id=${sessionId}`, data);
    }
}
