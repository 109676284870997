import { useTranslation } from "react-i18next";

export default function CGUNewsletter() {
    const { t } = useTranslation();
    document.title = `ezoom | ${t("CG")}`;
    return (
        <div className="flex flex-col h-full w-full md:w-2/3 px-4 md:px-0 gap-4 mx-auto pt-4 max-w-7xl">
            <h1 className="text-4xl font-bold text-orange-500">{t("CG")}</h1>

            <hr />
            <div className="flex flex-col gap-2">
                <span className="flex gap-2 text-lg font-bold text-black">{t("mentionslegales")}</span>
                <span className="flex gap-2 text-base" dangerouslySetInnerHTML={{ __html: t("mentionslegales_d")}}></span>
            </div>
            <hr />

            <p>{t("cgu_newsletter_d")}</p>
            <ol className="text-justify">
                <li className="flex flex-col gap-2 mt-2">
                    <p className="flex flex-row font-bold"><div className="w-8">1.</div> {t("cgu_newsletter_p1")}</p>
                    <p className="pl-8 text-sm">
                        {t("cgu_newsletter_p1_d")}
                    </p>
                </li>
                <li className="flex flex-col gap-2 mt-7">
                    <p className="flex flex-row font-bold"><div className="w-8">2.</div> {t("cgu_newsletter_p2")}</p>
                    <p className="pl-8 text-sm">
                        {t("cgu_newsletter_p2_d")}
                    </p>
                </li>
                <li className="flex flex-col gap-2 mt-7">
                    <p className="flex flex-row font-bold"><div className="w-8">3.</div> {t("cgu_newsletter_p3")}</p>
                    <p className="pl-8 text-sm">
                        {t("cgu_newsletter_p3_d")}
                    </p>
                </li>
                <li className="flex flex-col gap-2 mt-7">
                    <p className="flex flex-row font-bold"><div className="w-8">4.</div> {t("cgu_newsletter_p4")}</p>
                    <p className="pl-8 text-sm">
                        {t("cgu_newsletter_p4_d")}
                    </p>
                </li>
                <li className="flex flex-col gap-2 mt-7">
                    <p className="flex flex-row font-bold"><div className="w-8">5.</div> {t("cgu_newsletter_p5")}</p>
                    <p className="pl-8 text-sm">
                        {t("cgu_newsletter_p5_d")}
                    </p>
                </li>
            </ol>
            <p>{t("cgu_newsletter_lastMaj", { date: "décembre 2023"})}</p>
        </div>
    );
}
