import { PictureType } from "../../../components/Interfaces/PictureType";
import { GeneralReducerType, Items, Me, Regions } from "../../../components/Interfaces/GeneralReducerType";

export const updateCategories = (categories: Items[]): any => ({
    type: "UPDATE_CATEGORIES",
    payload: categories,
});

export const updateTypeStyles = (typesStyles: Items[]): any => ({
    type: "UPDATE_TYPESTYLES",
    payload: typesStyles,
});

export const updateLicences = (licences: Items[]): any => ({
    type: "UPDATE_LICENCES",
    payload: licences,
});

export const updateServiceTypes = (servciesTypes: Items[]): any => ({
    type: "UPDATE_SERVICETYPES",
    payload: servciesTypes,
});

export const updatePhotoStyles = (photoStyles: Items[]): any => ({
    type: "UPDATE_PHOTO_STYLES",
    payload: photoStyles,
});

export const updateRegions = (regions: Regions[]): any => ({
    type: "UPDATE_REGIONS",
    payload: regions,
});

export const updateMe = (me: Me): any => ({
    type: "UPDATE_ME",
    payload: me,
});

export const updateGeneral = (general: GeneralReducerType): any => ({
    type: "UPDATE_GENERAL",
    payload: general,
});