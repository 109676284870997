import { Storage } from "../../storage";
import APIClient from "../APIClients";

const token = Storage.getToken()

export default class TypeStyleAPI {
    public static async types() {
        if (token)
            return APIClient.ezoom.get(`/public/type-styles`);
        else
            return APIClient.noInterceptor.get(`/public/type-styles`)
    }

    public static async typesMostUsed() {
        if (token)
            return APIClient.ezoom.get(`/public/most-used-typestyles`);
        else
            return APIClient.noInterceptor.get(`/public/most-used-typestyles`)
    }

    public static async newType(data: {}) {
        return APIClient.ezoom.post(`/type-styles`, data);
    }

    public static async putType(data: {}, enumValue: string) {
        return APIClient.ezoom.put(`/type-styles/${enumValue}`, data);
    }

    public static async deleteType(enumValue: string) {
        return APIClient.ezoom.delete(`/type-styles/${enumValue}`);
    }
}
