import { Spinner } from "@material-tailwind/react";
import React from "react";
import { useTranslation } from "react-i18next";

export default function GradientButton({ text, disabled = false, isLoading = false }: Readonly<{ text: string, disabled?: boolean, isLoading?: boolean }>) {
    const { t } = useTranslation();

    return (
        <button
            className="border-2 bg-orange-500 hover:bg-orange-200 border-transparent inline w-auto text-white hover:text-black py-2 px-4 rounded-full disabled:bg-gray-200 disabled:hover:text-white"
            style={{
                transition: "all .25s ease-in-out",
            }}
            disabled={disabled}
        >
            {isLoading 
                ? <Spinner color="gray" />
                : <span className="flex">{t(text)}</span>
            }
        </button>
    );
}
