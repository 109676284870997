import { Reducer } from "@reduxjs/toolkit";
import { LittleUserType } from "../../../components/Interfaces/UserType";

const initialState: LittleUserType = {
    company: "",
    firstName: "",
    lastName: "",
    country: "",
    address: "",
    phoneNumber: "",
    email: "",
};

const littleUserReducer: Reducer<LittleUserType, any> = (state = initialState, action) => {
    switch (action.type) {        
        case "UPDATE_COMPANY":
            return { ...state, company: action.payload };
        case "UPDATE_FIRSTNAME":
            return { ...state, firstName: action.payload };
        case "UPDATE_LASTNAME":
            return { ...state, lastName: action.payload };
        case "UPDATE_COUNTRY":
            return { ...state, country: action.payload };
        case "UPDATE_ADDRESS":
            return { ...state, address: action.payload };
        case "UPDATE_PHONENUMBER":
            return { ...state, phoneNumber: action.payload };
        case "UPDATE_EMAIL":
            return { ...state, email: action.payload };
        case "UPDATE_USER":
            return { ...state, ...action.payload };
        default:
            return state;
    }
};

export default littleUserReducer;
