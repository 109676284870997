import { useState, useEffect } from "react";

function useWindowWidth() {
    const [windowWidth, setWindowWidth] = useState<number>(window.innerWidth);

    useEffect(() => {
        const handleResize = () => {
            setWindowWidth(window.innerWidth);
        };

        window.addEventListener("resize", handleResize);

        // Nettoyage de l'écouteur d'événement lors du démontage du composant
        return () => {
            window.removeEventListener("resize", handleResize);
        };
    }, []);

    return windowWidth;
}

export default useWindowWidth;
