import { Storage } from "../../storage";
import APIClient from "../APIClients";

const token = Storage.getToken()

export default class WrittenDocumentsAPI {
    public static async upload(data: FormData) {
        return APIClient.ezoom.postPicture(`/written-documents`, data)
    }

    public static async get(id: string) {
        return APIClient.ezoom.get(`/written-documents/${id}`)
    }
}
