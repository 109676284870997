import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";

function ColorPhoto(currentUrl: string) {
    if (currentUrl === "photos") return "text-white bg-orange-500 border-orange-500";
    else return "bg-white text-gray-500 border-gray-400 hover:text-orange-500 hover:border-orange-500 hover:z-10";
}

function ColorGrapher(currentUrl: string) {
    if (currentUrl === "photographs") return "text-white bg-purple-500 border-purple-500";
    else return "bg-white text-gray-500 border-gray-400 hover:text-purple-500 hover:border-purple-500";
}

export default function Research({
    onglet,
    setOnglet,
    kindOfResearch,
    setKindOfResearch,
    currentUrl,
}: Readonly<{ onglet: string; setOnglet: Function; kindOfResearch: string; setKindOfResearch: Function; currentUrl: string }>) {
    const { t } = useTranslation();

    useEffect(() => {
        if ((
                currentUrl === "/favorites" ||
                currentUrl === "/recent" ||
                currentUrl === "/galeries" ||
                currentUrl === "/popular" ||
                currentUrl.startsWith("/search/category/") ||
                currentUrl.startsWith("/search/typestyle/") ||
                currentUrl.startsWith("/search/license/") ||
                currentUrl.startsWith("/search/region/") ||
                currentUrl.startsWith("/search/date/")
            )
        )
            setOnglet("photos");

        if ((
                currentUrl === "/favorites/photographers" || 
                currentUrl === "/popular-photographers" || 
                currentUrl === "/recent-photographers" ||
                currentUrl.startsWith("/photographers/category/") ||
                currentUrl.startsWith("/photographers/style/") ||
                currentUrl.startsWith("/photographers/region")
            )
        )
            setOnglet("photographs");
    }, [currentUrl]);

    return (
        <>
            <div className="flex my-2">
                <div className="group flex relative w-1/2">
                    <span
                        className={`${ColorPhoto(
                            onglet
                        )} transition-all py-1.5 px-3 cursor-pointer border rounded-l-full text-sm flex items-center justify-center w-full`}
                        onClick={() => setOnglet("photos")}
                    >
                        {t("photos")}
                    </span>
                </div>
                <div className="group flex relative w-1/2">
                    <span
                        className={`${ColorGrapher(
                            onglet
                        )} transition-all py-1.5 px-3 cursor-pointer border rounded-r-full text-sm flex items-center justify-center w-full`}
                        onClick={() => setOnglet("photographs")}
                        style={{ marginLeft: "-1px" }}
                    >
                        {t("photographs")}
                    </span>
                </div>
            </div>
            {onglet === "photos" && (
                <div>
                    <Link to="/popular">
                        <button
                            className={`my-2 ${
                                currentUrl.startsWith("/popular")
                                    ? "bg-gradient-to-r from-orange-500 to-orange-100 text-white hover:opacity-80"
                                    : "hover:bg-gradient-to-r hover:from-orange-500 hover:to-orange-100 hover:text-white text-gray-500 bg-transparent"
                            } p-2 text-sm w-full text-left rounded-lg`}
                        >
                            <FontAwesomeIcon icon="fire" className="mr-2 w-4" />
                            {t("populars")}
                        </button>
                    </Link>
                    <Link to="/recent">
                        <button
                            className={`my-2 ${
                                (currentUrl ===("/recent") || currentUrl.startsWith("/recent?"))
                                    ? "bg-gradient-to-r from-orange-500 to-orange-100 text-white hover:opacity-80"
                                    : "hover:bg-gradient-to-r hover:from-orange-500 hover:to-orange-100 hover:text-white text-gray-500 bg-transparent"
                            } p-2 text-sm w-full text-left rounded-lg`}
                        >
                            <FontAwesomeIcon icon="clock" className="mr-2 w-4" />
                            {t("recents")}
                        </button>
                    </Link>
                    <Link to="/galeries">
                        <button
                            className={`my-2 ${
                                currentUrl.startsWith("/galeries")
                                    ? "bg-gradient-to-r from-orange-500 to-orange-100 text-white hover:opacity-80"
                                    : "hover:bg-gradient-to-r hover:from-orange-500 hover:to-orange-100 hover:text-white text-gray-500 bg-transparent"
                            } p-2 text-sm w-full text-left rounded-lg`}
                        >
                            <FontAwesomeIcon icon="images" className="mr-2 w-4" />
                            {t("galeries")}
                        </button>
                    </Link>
                    <button
                        onClick={() => setKindOfResearch("catsPhoto")}
                        className={`my-2 ${currentUrl.startsWith("/search/category") ? "bg-gradient-to-r from-orange-500 to-orange-100 text-white hover:opacity-80" : "hover:bg-gradient-to-r hover:from-orange-500 hover:to-orange-100 hover:text-white text-gray-500 bg-transparent"} p-2 text-sm w-full text-left rounded-lg flex justify-between items-center transition-all`}
                    >
                        <div className="flex flex-row items-center">
                            <FontAwesomeIcon icon="folder" className="mr-2 w-4" />
                            {t("categories")}
                        </div>
                        <FontAwesomeIcon icon="chevron-right" />
                    </button>
                    <button
                        onClick={() => setKindOfResearch("typesPhoto")}
                        className={`my-2 ${currentUrl.startsWith("/search/typestyle") ? "bg-gradient-to-r from-orange-500 to-orange-100 text-white hover:opacity-80" : "hover:bg-gradient-to-r hover:from-orange-500 hover:to-orange-100 hover:text-white text-gray-500 bg-transparent"} p-2 text-sm w-full text-left rounded-lg flex justify-between items-center transition-all`}
                    >
                        <div className="flex flex-row items-center">
                            <FontAwesomeIcon icon="th" className="mr-2 w-4" />
                            {t("type")}
                        </div>
                        <FontAwesomeIcon icon="chevron-right" />
                    </button>
                    <button
                        onClick={() => setKindOfResearch("rightsPhoto")}
                        className={`my-2 ${currentUrl.startsWith("/search/license") ? "bg-gradient-to-r from-orange-500 to-orange-100 text-white hover:opacity-80" : "hover:bg-gradient-to-r hover:from-orange-500 hover:to-orange-100 hover:text-white text-gray-500 bg-transparent"} p-2 text-sm w-full text-left rounded-lg flex justify-between items-center transition-all`}
                    >
                        <div className="flex flex-row items-center">
                            <FontAwesomeIcon icon="copyright" className="mr-2 w-4" />
                            {t("userights")}
                        </div>
                        <FontAwesomeIcon icon="chevron-right" />
                    </button>
                    <button
                        onClick={() => setKindOfResearch("regionsPhoto")}
                        className={`my-2 ${currentUrl.startsWith("/search/region") ? "bg-gradient-to-r from-orange-500 to-orange-100 text-white hover:opacity-80" : "hover:bg-gradient-to-r hover:from-orange-500 hover:to-orange-100 hover:text-white text-gray-500 bg-transparent"} p-2 text-sm w-full text-left rounded-lg flex justify-between items-center transition-all`}
                    >
                        <div className="flex flex-row items-center">
                            <FontAwesomeIcon icon="globe" className="mr-2 w-4" />
                            {t("regions")}
                        </div>
                        <FontAwesomeIcon icon="chevron-right" />
                    </button>
                    <button
                        onClick={() => setKindOfResearch("datePhoto")}
                        className={`my-2 ${currentUrl.startsWith("/search/date") ? "bg-gradient-to-r from-orange-500 to-orange-100 text-white hover:opacity-80" : "hover:bg-gradient-to-r hover:from-orange-500 hover:to-orange-100 hover:text-white text-gray-500 bg-transparent"} p-2 text-sm w-full text-left rounded-lg flex justify-between items-center transition-all`}
                    >
                        <div className="flex flex-row items-center">
                            <FontAwesomeIcon icon="calendar" className="mr-2 w-4" />
                            {t("date")}
                        </div>
                        <FontAwesomeIcon icon="chevron-right" />
                    </button>
                </div>
            )}
            {onglet === "photographs" && (
                <div>
                    <Link to="/popular-photographers">
                        <button className={`my-2 ${currentUrl.startsWith("/popular-photographers") ? "bg-gradient-to-r from-purple-500 to-purple-100 text-white hover:opacity-80" : "hover:bg-gradient-to-r hover:from-purple-500 hover:to-purple-100 hover:text-white text-gray-500 bg-transparent"} p-2 text-sm w-full text-left rounded-lg transition-all`}>
                            <FontAwesomeIcon icon="fire" className="mr-2 w-4" />
                            {t("populars")}
                        </button>
                    </Link>
                    <Link to="/recent-photographers">
                        <button className={`my-2 ${currentUrl.startsWith("/recent-photographers") ? "bg-gradient-to-r from-purple-500 to-purple-100 text-white hover:opacity-80" : "hover:bg-gradient-to-r hover:from-purple-500 hover:to-purple-100 hover:text-white text-gray-500 bg-transparent"} p-2 text-sm w-full text-left rounded-lg transition-all`}>
                            <FontAwesomeIcon icon="clock" className="mr-2 w-4" />
                            {t("recentsphotographers")}
                        </button>
                    </Link>
                    <button
                        onClick={() => setKindOfResearch("servicesPhotograph")}
                        className={`my-2 ${currentUrl.startsWith("/photographers/category/") ? "bg-gradient-to-r from-purple-500 to-purple-100 text-white hover:opacity-80" : "hover:bg-gradient-to-r hover:from-purple-500 hover:to-purple-100 hover:text-white text-gray-500 bg-transparent"} p-2 text-sm w-full text-left rounded-lg flex justify-between items-center transition-all`}
                    >
                        <div className="flex flex-row items-center">
                            <FontAwesomeIcon icon="image" className="mr-2 w-4" />
                            {t("performances")}
                        </div>
                        <FontAwesomeIcon icon="chevron-right" />
                    </button>
                    <button
                        onClick={() => setKindOfResearch("stylesPhotograph")}
                        className={`my-2 ${currentUrl.startsWith("/photographers/style/") ? "bg-gradient-to-r from-purple-500 to-purple-100 text-white hover:opacity-80" : "hover:bg-gradient-to-r hover:from-purple-500 hover:to-purple-100 hover:text-white text-gray-500 bg-transparent"} p-2 text-sm w-full text-left rounded-lg flex justify-between items-center transition-all`}
                    >
                        <div className="flex flex-row items-center">
                            <FontAwesomeIcon icon="mountain-sun" className="mr-2 w-4" />
                            {t("style")}
                        </div>
                        <FontAwesomeIcon icon="chevron-right" />
                    </button>
                    <button
                        onClick={() => setKindOfResearch("regionsPhotograph")}
                        className={`my-2 ${currentUrl.startsWith("/photographers/region/") ? "bg-gradient-to-r from-purple-500 to-purple-100 text-white hover:opacity-80" : "hover:bg-gradient-to-r hover:from-purple-500 hover:to-purple-100 hover:text-white text-gray-500 bg-transparent"} p-2 text-sm w-full text-left rounded-lg flex justify-between items-center transition-all`}
                    >
                        <div className="flex flex-row items-center">
                            <FontAwesomeIcon icon="globe" className="mr-2 w-4" />
                            {t("regions")}
                        </div>
                        <FontAwesomeIcon icon="chevron-right" />
                    </button>
                </div>
            )}
        </>
    );
}
