import { Storage } from "../../storage";
import APIClient from "../APIClients";

const token = Storage.getToken()

export default class ServiceTypeAPI {
    public static async services() {
        if (token)
            return APIClient.ezoom.get(`/public/service-types`)
        else
            return APIClient.noInterceptor.get(`/public/service-types`)
    }

    public static async servicesMostUsed() {
        if (token)
            return APIClient.ezoom.get(`/public/most-used-service-types`)
        else
            return APIClient.noInterceptor.get(`/public/most-used-service-types`)
    }
}
