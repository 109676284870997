export const PlaceCustomStyle = {
    menuPortal: (base: any) => ({
        ...base,
        zIndex: 9999,
    }),
    menubar: (base: any) => ({
        ...base,
    }),
    menuList: (base: any) => ({
        ...base,
        padding: "0 0.5rem",
    }),
    control: (baseStyles: any, state: { isFocused: boolean, isDisabled: boolean }) => ({
        ...baseStyles,
        boxShadow: state.isFocused ? "0 0 0 1px rgb(239, 147, 53)" : "",
        borderColor: state.isFocused ? "rgb(239, 147, 53)" : state.isDisabled ? "rgb(236, 239, 241)" : "rgb(176, 190, 197)",
        backgroundColor: state.isDisabled ? "rgb(236, 239, 241)" : "white",
        color: state.isDisabled ? "rgb(69, 90, 100)" : "rgb(128, 128, 128)",
        "&:hover": {
            borderColor: state.isFocused ? "rgb(239, 147, 53)" : "rgb(176, 190, 197)",
        },
        fontSize: "14px"
    }),
    option: (base: any, state: { isSelected: boolean; isFocused: boolean }) => ({
        ...base,
        background: state.isSelected ? "linear-gradient(#ef9335, #F5BA56)" : "white",
        color: state.isSelected ? "white" : "black",
        "&:hover": {
            background: state.isSelected ? "linear-gradient(#ef9335, #F5BA56)" : "lightgray",
            color: state.isSelected ? "white" : "black",
        },
        borderRadius: "0.375rem",
        marginTop: "0.5rem",
        marginBottom: "0.5rem",
    }),
    placeholder: (base: any) => ({
        ...base,
        color: "rgb(96, 125, 139)",
        fontSize: "14px"
    }),
    singleValue: (base: any) => ({
        ...base,
        color: "rgb(96, 125, 139)",
        fontSize: "14px"
    }),
};

export const PlaceCustomStyleBorder = {
    menuPortal: (base: any) => ({
        ...base,
        zIndex: 9999,
    }),
    menubar: (base: any) => ({
        ...base,
    }),
    menuList: (base: any) => ({
        ...base,
        padding: "0 0.5rem",
    }),
    control: (baseStyles: any, state: { isFocused: boolean, isDisabled: boolean }) => ({
        ...baseStyles,
        borderWidth: "1px",
        borderRadius: state.isFocused ? 0 : 0,
        borderTopColor: state.isFocused ? "white" : "white",
        borderLeftColor: state.isFocused ? "white" : "white",
        borderRightColor: state.isFocused ? "white" : "white",
        borderBottomColor: state.isFocused ? "rgb(239, 147, 53)" : "rgb(107, 114, 128)",
        boxShadow: state.isFocused ? "0 0 0 1px white" : "",
        backgroundColor: "white",
        color: "rgb(128, 128, 128)",
        fontSize: "16px",
        padding: "0rem",
        "&:hover": {
            borderTopColor: state.isFocused ? "white" : "white",
            borderLeftColor: state.isFocused ? "white" : "white",
            borderRightColor: state.isFocused ? "white" : "white",
            borderBottomColor: state.isFocused ? "rgb(239, 147, 53)" : "rgb(107, 114, 128)",
        },
    }),
    option: (base: any, state: { isSelected: boolean; isFocused: boolean }) => ({
        ...base,
        background: state.isSelected ? "linear-gradient(#ef9335, #F5BA56)" : "white",
        color: state.isSelected ? "white" : "black",
        "&:hover": {
            background: state.isSelected ? "linear-gradient(#ef9335, #F5BA56)" : "lightgray",
            color: state.isSelected ? "white" : "black",
        },
        borderRadius: "0.375rem",
        marginTop: "0.5rem",
        marginBottom: "0.5rem",
    }),
    placeholder: (base: any) => ({
        ...base,
        color: "rgb(96, 125, 139)",
        fontSize: "16px",
        fontWeight: 400,
        padding: "0rem",
    }),
    singleValue: (base: any) => ({
        ...base,
        color: "rgb(0, 0, 0)",
        fontSize: "16px",
        fontWeight: 400,
        padding: "0rem",
    }),
};
