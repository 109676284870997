import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Dialog } from "@headlessui/react";
import { Avatar, IconButton, List, ListItem, ListItemPrefix, ListItemSuffix, SpeedDial, SpeedDialHandler, Typography } from "@material-tailwind/react";
import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import GradientButton from "./Form/Button/GradientButton";
import ModalWrapper from "./Modals/ModalWrapper";


interface CartItem {
    id: string,
    minPath: string,
    price: number
}

interface PanierProps {
    itemsInCart: CartItem[];
    removeFromCart: (id: string) => void;
    clearCart: () => void;
}

export default function Panier({ itemsInCart, removeFromCart, clearCart }: Readonly<PanierProps>) {
    const { t } = useTranslation();

    const [open, setOpen] = React.useState(false);

    useEffect(() => {
        if (itemsInCart.length === 0) {
            setOpen(false);
        }
    }, [itemsInCart]);

    return (
        <>
            <ModalWrapper open={open} setOpen={setOpen}>
                <Dialog.Panel className="transform overflow-hidden rounded-2xl bg-white px-16 py-12 text-left align-middle shadow-xl transition-all max-w-3xl">
                    <Dialog.Title as="h3" className="text-3xl font-medium leading-6 text-gray-900 text-center">
                        {t("yourcart")}
                    </Dialog.Title>
                    <div>
                        <p className="text-lg text-center text-gray-500">{itemsInCart && itemsInCart.length > 0 && itemsInCart.length} {t("articles")}</p>
                    </div>

                    <div className="w-full max-h-60 overflow-y-auto mt-4">
                        <List>
                            {itemsInCart && itemsInCart.length > 0 && itemsInCart.map((item, index: number) => (
                                <ListItem key={item.id}>
                                    <ListItemPrefix>
                                        <Avatar variant="rounded" alt={item.id} src={item.minPath} />
                                    </ListItemPrefix>
                                    <div>
                                        <Typography variant="h6" color="blue-gray">
                                            {item.id}
                                        </Typography>
                                        <Typography variant="small" color="gray" className="font-normal">
                                            {t("chf")} {item.price}
                                        </Typography>
                                    </div>
                                    <ListItemSuffix>
                                        <IconButton variant="text" color="blue-gray">
                                            <FontAwesomeIcon icon="trash" onClick={() => removeFromCart(item.id)} />
                                        </IconButton>
                                    </ListItemSuffix>
                                </ListItem>
                            ))}
                        </List>
                    </div>

                    <div className="flex justify-center gap-4 mt-6">
                        <button
                            type="button"
                            className="rounded-full border bg-white px-4 py-2 text-sm font-medium  hover:shadow-xl transition-all font-sans"
                            onClick={() => clearCart()}
                        >
                            {t("clearcart")}
                        </button>
                        <Link to="/checkout" className="w-fit" onClick={() => setOpen(false)}>
                            <GradientButton text={t("proceed")} />
                        </Link>
                    </div>
                </Dialog.Panel>
            </ModalWrapper>
            {itemsInCart.length > 0 && (
                <div className="fixed bottom-4 right-4 pl-4 z-30">
                    <SpeedDial>
                        <SpeedDialHandler>
                            <IconButton size="lg" className="rounded-full" color="orange" onClick={() => setOpen(!open)}>
                                <FontAwesomeIcon icon="cart-shopping" className="pt-1 h-5 w-5" />
                            </IconButton>
                        </SpeedDialHandler>
                    </SpeedDial>
                </div>
            )}
        </>
    );
}
